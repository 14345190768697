@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.top {
  &__tabs {
    display: flex;
    overflow: visible;
    margin: rem(16) auto 0 0;

    @media (max-width: $tablet) {
      width: 100%;
    }

    & > div {
      & > div {
        width: auto !important;
      }
    }
  }
}
