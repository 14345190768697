@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.profile {
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  padding: rem(24);
  border-radius: rem(20);

  @media (max-width: $pc) {
    padding: rem(16);
  }

  &Top {
    display: flex;
    gap: rem(16);
    align-items: center;

    @media (max-width: $mobile) {
      gap: rem(12);
    }

    &__img {
      flex: 0 0 auto;
      width: rem(110);
      height: rem(110);
      border-radius: 50%;
      overflow: hidden;

      @media (max-width: $mobile) {
        width: rem(40);
        height: rem(40);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      gap: rem(16);

      @media (max-width: $pc) {
        flex-wrap: wrap;
      }
      @media (max-width: $tablet) {
        flex-wrap: unset;
      }
      @media (max-width: $mobile) {
        gap: rem(8);
      }
    }
    &__name {
      display: flex;
      align-items: center;
      gap: rem(4);

      &Labels {
        display: flex;
        align-items: center;
        gap: rem(4);

        span {
          padding: rem(2) rem(8);
          border-radius: rem(5);
          font-weight: 500;
          font-size: rem(12);
          line-height: 130%;
          text-align: center;

          &.blue {
            color: cl(other-blue);
            background-color: cl(other-blue, 0.2);
          }
          &.yellow {
            color: cl(other-yellow);
            background-color: cl(other-yellow, 0.2);
          }
        }
      }
    }
    &__label {
      margin: rem(2) 0 0;
    }
    &__address {
      margin: rem(12) 0 0;
      display: flex;
      gap: rem(4);

      @media (max-width: $mobile) {
        margin: rem(16) 0 0;
      }

      &Chain {
        padding: rem(8) rem(12);
        display: flex;
        align-items: center;
        gap: rem(4);
        background: cl(opacity-light-5);
        border-radius: rem(12);

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: rem(16);
          height: rem(16);
          background-color: rgba($color: #edf0f4, $alpha: 0.1);
          border-radius: rem(5);

          svg {
            width: rem(8.4);
            height: rem(8.4);
          }
        }
      }
      &Input {
        border-radius: rem(12);
      }
    }
    &__col {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        flex: 0 0 rem(425);
        // flex: 1 1 auto;
      }
    }
    &__exchanges {
      display: flex;
      gap: rem(8);

      &Item {
        width: rem(20);
        height: rem(20);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &Social {
    width: 100%;
    min-width: 0;
    position: relative;
    margin: rem(32) 0 0;
    padding: rem(10);
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(20);

    @media (max-width: $mobile) {
      margin: rem(32) 0 0 rem(-16);
      width: calc(100% + rem(32));
      border: none;
      border-top: rem(1) solid cl(opacity-light-10);
      border-bottom: rem(1) solid cl(opacity-light-10);
      border-radius: 0;
      overflow: hidden;
    }

    .profileSocial__prev,
    .profileSocial__next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: rem(20);
      height: rem(20);
      border-radius: 50%;
      background-color: #26262a;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all $time $tr;

      &:hover {
        background-color: #44444b;
      }

      @media (max-width: $mobile) {
        display: none !important;
      }

      svg {
        width: rem(12);
        height: rem(12);
      }
    }

    .profileSocial__prev {
      left: rem(-10);
    }
    .profileSocial__next {
      right: rem(-10);

      svg {
        transform: scale(-1, 1);
      }
    }

    &__slider {
      @media (max-width: $mobile) {
        overflow: visible;
      }

      &Slide {
        width: auto;

        &:last-child {
          .socialCard {
            padding: 0 rem(10) 0 0;
            border: none;
          }
        }
      }
    }
    .socialCard {
      display: flex;
      align-items: center;
      gap: rem(9);
      padding: 0 rem(20) 0 0;
      border-right: rem(1) solid cl(opacity-light-10);

      &__img {
        flex: 0 0 auto;
        width: rem(32);
        height: rem(32);
        border-radius: rem(12);
        background-color: cl(opacity-light-5);

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: rem(14);
          height: rem(14);
          object-fit: contain;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: rem(2);
      }
    }
  }

  &Summary {
    margin: rem(22) 0 0;

    @media (max-width: $mobile) {
      margin: rem(32) 0 0;
    }

    &__title {
    }
    &__content {
      margin: rem(12) 0 0;
      display: flex;
      gap: rem(12);

      @media (max-width: $mobile) {
        margin: rem(16) 0 0;
        gap: rem(8);
        flex-wrap: wrap;
      }
    }

    .summaryItem {
      flex: 0 0 calc(25% - rem(9));
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(8);
      background: cl(opacity-light-5);
      border-radius: rem(20);
      padding: rem(14);

      @media (max-width: $mobile) {
        flex: 0 0 calc(50% - rem(4));
      }
      &__category {
        margin: 0 0 rem(8);
        border-radius: rem(5);
        padding: rem(2) rem(8);

        backdrop-filter: blur(rem(12));
        background: rgba(209, 255, 77, 0.1);

        font-weight: 500;
        font-size: rem(12);
        line-height: 130%;
        text-align: center;
        color: #d1ff4d;
      }
      &__text {
        margin: rem(-6) 0 0;
      }
    }
  }

  &About {
    margin: rem(24) 0 0;

    @media (max-width: $mobile) {
      margin: rem(32) 0 0;
    }
    &__text {
      margin: rem(12) 0 0;

      @media (max-width: $mobile) {
        margin: rem(16) 0 0;
      }

      a {
        color: cl(main-light);

        &:hover {
          color: cl(opacity-light-80);
        }
      }
    }
  }
}
