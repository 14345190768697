@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(32) rem(12);
  gap: rem(24);
  backdrop-filter: blur(rem(100));
  border-radius: rem(24);
  background: cl(opacity-light-5);

  @media (max-width: $mobile) {
    display: none;
  }

  &__title {
  }
  &Body {
    display: flex;
    gap: rem(24);
    flex-direction: column;

    &__chart {
      --p: 0;
      --b: 20px;
      --w: 160px;
      --c: #337ab7;

      width: var(--w);
      aspect-ratio: 1;
      position: relative;
      display: inline-grid;
      place-content: center;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
      }
      &:before {
        inset: 0;
        background: radial-gradient(farthest-side, cl(other-green) 98%, #0000)
            top/var(--b) var(--b) no-repeat,
          conic-gradient(
            cl(other-green) calc(var(--p) * 1%),
            cl(opacity-light-5) 0
          );
        mask: radial-gradient(
          farthest-side,
          #0000 calc(99% - var(--b)),
          #000 calc(100% - var(--b))
        );
      }
      &:after {
        inset: calc(50% - var(--b) / 2);
        background: cl(other-green);
        transform: rotate(calc(var(--p) * 3.6deg))
          translateY(calc(50% - var(--w) / 2));
      }

      /* Анимация */
      @property --p {
        syntax: '<number>';
        inherits: true;
        initial-value: 0;
      }
      .pieanimate {
        animation: p 1s 0.5s both;
      }
      @keyframes p {
        from {
          --p: 0;
        }
      }
    }
    &__categories {
      display: flex;
      flex-direction: column;
      gap: rem(16);
    }
  }
}

.category {
  display: flex;
  gap: rem(8);

  &__progress {
  }
  &__name {
  }
}
