@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.header {
  position: fixed;
  z-index: 11;
  left: 0;
  width: 100%;
  transition: all $time $tr;

  &.searchOpen {
    .con {
    }
    .headerBody__search {
      max-width: calc(rem($maxWidthContainer) + rem(32));
      width: calc(100% - rem(32));
      box-sizing: border-box;
      // padding: 0 rem(16);

      position: fixed;
      z-index: 11;
      left: 50%;
      transform: translate(-50%, 0);

      @media (max-width: $tablet) {
        transform: translate(0);
        left: auto;
        position: static;
        width: auto;
        margin: 0;
        width: 100%;
      }
    }
  }

  &.scrolled {
    transform: translate(0%, -100%);
    box-shadow: 0 rem(4) rem(4) 0 cl(opacity-light-10);
    border-bottom: rem(1) solid cl(opacity-light-10);
    background-color: cl(main-dark);

    // @media (max-width: $tablet) {
    //   transform: translate(0);
    // }

    .headerStats {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
    .headerContent {
      backdrop-filter: none;
    }

    &.visible {
      transform: translate(0);

      &.open {
        transition: none;
        backdrop-filter: none;
        transform: none;
      }
    }
  }

  &Stats {
    padding: rem(16) 0;
    display: flex;
    justify-content: center;
    transition: all $time $tr;

    @media (max-width: $tablet) {
      // margin: 0 0 0 rem(16);

      &::before {
        content: '';
        margin: 0 rem(16);
        width: rem(2);
        height: rem(18);
        border-radius: rem(20);
        background-color: cl(opacity-light-10);
      }
    }
  }
  &Marquee {
    position: relative;
    width: calc(100% + rem(32)) !important;
    transform: translate(rem(-16), 0) !important;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      width: rem(100);
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(1, 1, 6, 1) 0%,
        rgba(1, 1, 6, 0) 100%
      );
      // background: red;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      transform: scale(-1, 1);
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    padding: rem(8) rem(8) rem(16);
    border-radius: rem(16);
    background-color: cl(opacity-light-5);

    @media (max-width: $tablet) {
      backdrop-filter: blur(rem(200));
      // background: cl(opacity-light-10);
      position: relative;
      z-index: 11;
      padding: rem(8) rem(8) rem(10);
    }
  }

  &Body {
    display: flex;
    gap: rem(8);

    &__logo {
      flex: 0 0 auto;
      margin: 0 rem(28);
      width: rem(114);

      @media (max-width: $tablet) {
        margin: 0 0 0 rem(14);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__search {
      width: 100%;
      transition: all $time $tr;

      @media (max-width: $tablet) {
        width: auto;
        // padding: rem(12);
        margin: 0 0 0 auto;
      }
    }
    &__btns {
      display: flex;
      gap: rem(8);

      button {
        white-space: nowrap;
      }
    }
  }

  &Links {
    display: flex;
    justify-content: center;
    gap: rem(16);
  }
}

.statsItem {
  display: flex;
  gap: rem(2);
  align-items: center;
  white-space: nowrap;

  &:not(:first-child) {
    &::before {
      content: '';
      margin: 0 rem(16);
      width: rem(2);
      height: rem(18);
      border-radius: rem(20);
      background-color: cl(opacity-light-10);
    }
  }
}
