@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.img {
  &__item {
    border-radius: rem(20);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__text {
    margin: rem(8) auto 0;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(16);
  margin: 0 auto rem(8);
  max-width: rem(740);
}
