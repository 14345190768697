@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.details {
  margin: rem(32) 0 0;

  &__title {
  }
  &Body {
    display: flex;
    margin: rem(24) 0 0;
    gap: rem(16);

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
      flex-direction: column;
      gap: rem(8);
    }

    &__col {
      border-radius: rem(20);
      backdrop-filter: blur(rem(100));
      background: cl(opacity-light-5);
      padding: rem(24);

      @media (max-width: $tablet) {
        padding: rem(24) rem(16) rem(16);
      }

      &.detailsBody__graph {
        flex: 1 1 rem(763);
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $tablet) {
          flex: 0 0 rem(240);
        }

        svg {
          width: rem(36);
        }
      }
      &.detailsBody__data {
        flex: 1 1 rem(565);

        @media (max-width: $tablet) {
          flex: 1 1 auto;
        }
      }
    }
    &__data {
      &Top {
        display: flex;
        align-items: center;
        gap: rem(4);
      }
      &Title {
      }
      &Link {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);

        svg {
          path {
            transition: all $time $tr;
          }
        }

        &:hover {
          svg {
            path {
              stroke: cl(main-light);
            }
          }
        }
      }
      &Content {
        margin: rem(24) 0 0;
        border: rem(1) solid cl(opacity-light-10);
        border-radius: rem(20);
      }
    }
  }
}

.dataRow {
  display: flex;
  gap: rem(8);
  align-items: center;
  padding: rem(22) rem(24);

  &:not(:first-child) {
    border-top: rem(1) solid cl(opacity-light-10);
  }

  &__icon {
  }
  &__title {
  }
  &__value {
    margin: 0 0 0 auto;
  }
  &__diff {
    margin: 0 0 0 auto;

    display: flex;
    align-items: center;
    gap: rem(3);

    svg {
      flex: 0 0 auto;
      width: rem(6);
    }

    &.green {
      color: #78e053;

      svg {
        path {
          fill: #78e053;
        }
      }
    }
  }
}
