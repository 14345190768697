@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.input {
  padding: rem(12) rem(16);
  display: flex;
  align-items: center;
  gap: rem(8);
  border-radius: rem(16);
  width: 100%;
  height: rem(40);
  background-color: cl(opacity-light-5);

  &.big {
    padding: rem(16);
    height: rem(50);
  }

  &.file,
  &.image {
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  &.border {
    background-color: transparent;
    border: rem(1) solid cl(opacity-light-10);
  }

  &:hover {
    .inputFile {
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }

  &File {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      flex: 0 0 auto;
      width: rem(16);
      height: rem(16);

      path {
        transition: all $time $tr;
      }
    }
  }

  &__icon {
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    margin: 0 rem(-4) 0 0;
    display: none;
    &.visible {
      display: block;
    }
  }

  &__info {
    cursor: pointer;
    width: rem(16);
    height: rem(16);

    &:hover {
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }

  &__clear {
    cursor: pointer;
    width: rem(16);
    height: rem(16);

    display: none;

    &.visible {
      display: block;
    }

    &:hover {
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }

  &__percent {
    // position: absolute;
    // top: 50%;
    // transform: translate(0, -50%);
    // right: 0;
  }

  &.disabled {
    input {
      color: cl(opacity-light-50);
    }
  }
  &.dark {
    border-color: rgba(1, 1, 6, 0.1);
    input {
      color: rgba(1, 1, 6, 1);

      &::placeholder {
        color: rgba(1, 1, 6, 0.5);
      }
    }
  }

  input {
    width: 100%;
    height: 100%;
    background-color: transparent;

    font-weight: 400;
    font-size: rem(12);
    line-height: 130%;
    color: cl(main-light);

    &::placeholder {
      color: cl(opacity-light-50);
    }
  }
}
