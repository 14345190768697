@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.upload {
  cursor: pointer;
  display: block;
  position: relative;
  width: rem(160);
  height: rem(160);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  padding: rem(6);
  overflow: hidden;

  &:hover {
    .uploadBody {
      border-color: rgba(255, 255, 255, 0.25);
    }
  }

  &.big {
    width: 100%;
    height: 100%;
    .upload {
      &Body {
        padding: rem(32);
        &__text {
          font-size: rem(12);
          max-width: rem(194);
          margin: 0 auto;
        }
      }
    }
  }

  &.transparent {
    background: transparent;
    padding: 0;
    overflow: visible;
  }

  input {
    position: absolute;
    opacity: 0;

    top: 0;
    left: 0;
  }

  &Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(16);
    width: 100%;
    height: 100%;
    border: rem(1) dashed rgba(255, 255, 255, 0.1);
    border-radius: rem(24);
    padding: rem(0) rem(30);
    transition: all $time $tr;

    &__icon {
      width: rem(24);
      height: rem(24);
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      font-size: rem(9);
    }
  }

  &Img {
    position: relative;
    width: 100%;
    height: 100%;
    border: rem(1) dashed rgba(255, 255, 255, 0.1);
    border-radius: rem(24);
    background: cl(opacity-light-5);
    overflow: hidden;

    &__btn {
      position: absolute;

      &:nth-child(1) {
        top: rem(8);
        left: rem(8);
      }
      &:nth-child(2) {
        top: rem(8);
        right: rem(8);
      }
    }
    &__item {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
