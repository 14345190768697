@use "sass:math";
@import "/src/scss/style.scss";
@import "/src/scss/mixins";
@import "/src/scss/colors-palette.scss";

.card {
  position: relative;
  padding: rem(32) rem(96);
  backdrop-filter: blur(rem(100));
  background: rgba(255, 255, 255, 0.05);
  border-radius: rem(24);

  &:not(:first-child) {
    margin: rem(16) 0 0;
  }

  @media (max-width: $tablet) {
    padding: rem(24) rem(16);
  }

  &__remove {
    cursor: pointer;
    position: absolute;
    top: rem(32);
    right: rem(32);
    padding: 0;
  }

  &__content {
    margin: rem(24) 0 0;
  }

  &__label {
    margin: rem(8) 0 0;
  }
}
