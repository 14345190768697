@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.switcher {
  cursor: pointer;
  display: inline-flex;
  position: relative;

  input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:checked + .switcherBody {
      .switcherBody {
        &__item {
          span {
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
  &Body {
    display: flex;
    gap: rem(8);
    justify-content: center;
    align-items: center;

    &__text {
    }
    &__item {
      display: flex;
      width: rem(34);
      padding: rem(2);
      border-radius: rem(8);
      background: cl(opacity-light-10);

      span {
        display: block;
        border-radius: rem(6);
        width: rem(16);
        height: rem(16);
        background-color: cl(main-light);
        margin: 0 auto 0 0;

        transition: all $time $tr;
      }
    }
  }
}
