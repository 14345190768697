@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.category {
  &Body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(8);

    &__item {
      border-radius: rem(12);
      padding: rem(8) rem(12) !important;
    }
  }
}

.categoryItem__wrapper {
  position: relative;

  input {
    cursor: pointer;
    position: absolute;
    // z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
