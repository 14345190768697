@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.slider {
  position: relative;
  &__item {
  }
  &__nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0, -50%);
    width: rem(24);
    height: rem(24);
    border-radius: 50%;

    backdrop-filter: blur(11.899999618530273px);
    background: cl(whte);

    &:nth-child(1) {
      left: rem(-12);

      svg {
        transform: scale(-1, 1);
      }
    }
    &:nth-child(2) {
      right: rem(-12);
    }

    svg {
      pointer-events: none;
    }
  }
  &__slide {
    width: rem(116) !important;
  }
}
