@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.purchase {
  border-radius: rem(24);
  background: cl(opacity-light-5);
  padding: rem(24);
  display: flex;
  flex-direction: column;

  &__title {
  }
  &Cards {
    margin: rem(24) 0 auto;
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }
  &Summary {
    // margin: auto 0 rem(32);
    // padding: rem(24) 0 0;
    margin: rem(24) 0 rem(32);
    &__title {
      margin: 0 0 rem(16);
    }
    &__row {
      margin: rem(8) 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__btn {
      width: 100%;
      margin: rem(32) 0 0;
    }
  }
  &Payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0;

    &__text {
    }
    &__icons {
      display: flex;
      gap: rem(5);

      img {
        width: rem(32);
        height: rem(32);
      }
    }
  }
}

.toSectionBtn {
  position: fixed;
  bottom: rem(24);
  left: rem(16);
  right: rem(16);
  opacity: 0;
  pointer-events: none;
  user-select: none;
  visibility: hidden;
  transform: translate(0, rem(24));
  transition: all $time $tr;

  span {
    color: rgba(1, 1, 6, 0.5);
  }

  &.visible {
    transform: translate(0);
    opacity: 1;
    pointer-events: unset;
    user-select: unset;
    visibility: visible;
  }
}

.empty {
  margin: rem(40) auto auto;

  @media (max-width: $mobile) {
    margin: rem(64) auto rem(90);
  }
}
