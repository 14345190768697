@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.timer {
  display: flex;
  gap: rem(4);

  &__item {
    position: relative;
    height: rem(60);
    flex: 0 0 calc(33.333% - rem(2.666));
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(12);
    background: cl(opacity-light-5);

    &Front {
      position: absolute;
    }

    &Back {
      position: absolute;
    }
  }
}
