@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.graph {
  display: flex;
  flex-direction: column;
  gap: rem(16);
  padding: rem(24);
  border-radius: rem(24);
  background: cl(opacity-light-5);

  &Top {
    &__title {
    }
    &__legend {
      margin: rem(8) 0 0;
      display: flex;
      gap: rem(16);

      &Item {
        display: flex;
        align-items: center;
        gap: rem(4);

        &.yellow {
          span {
            background-color: cl(other-yellow);
          }
        }
        &.purple {
          span {
            background-color: cl(other-purple);
          }
        }

        span {
          width: rem(8);
          height: rem(8);
          flex: 0 0 auto;
          border-radius: rem(3);
        }
      }
    }
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    &__content {
      position: relative;
      z-index: 1;
      height: rem(192);
    }
    &__lines {
      position: absolute;
      top: 50%;
      left: rem(-24);
      right: rem(-24);
      transform: translate(0, -50%);

      display: flex;
      flex-direction: column;
      gap: rem(16);
    }
    &__item {
      position: relative;
      width: 100%;
      height: rem(40);
      background-image: url(./assets/bg-lines.svg);
      background-size: contain;
      background-repeat: repeat;
      // backdrop-filter: blur(rem(32));

      &:hover {
        .progress {
          &::after {
            opacity: 1;
            visibility: visible;
            pointer-events: unset;
            user-select: unset;
            transform: scale(1) translate(0, -50%);
          }
        }
        .tip {
          opacity: 1;
          visibility: visible;
          pointer-events: unset;
          user-select: unset;
        }
      }

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        backdrop-filter: blur(rem(32));
      }

      .tip {
        position: absolute;
        z-index: 1;
        top: rem(14);
        right: rem(14);
        transform: translate(100%, -100%);
        padding: rem(12) rem(16);
        backdrop-filter: blur(rem(16));
        background: rgba(1, 1, 6, 0.5);
        border-radius: rem(9);

        display: flex;
        flex-direction: column;
        gap: rem(2);

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        user-select: none;

        transition: all $time $tr;

        &__value {
          display: flex;
          align-items: center;

          img {
            flex: 0 0 auto;
            width: rem(16);
            height: rem(16);
          }
        }
      }

      .progress {
        position: relative;
        width: 50%;
        height: 100%;
        border-radius: 0 rem(12) rem(12) 0;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: rem(14);
          transform: scale(1) translate(0, -50%);
          width: rem(12);
          height: rem(12);
          border: rem(3) solid #202025;
          border-radius: 50%;

          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          user-select: none;

          transition: all $time $tr;
        }
      }

      &.purple {
        background-color: rgba(173, 117, 245, 0.05);

        .progress {
          background: linear-gradient(90deg, #ad75f5 0%, #a993c7 100%);
        }
      }
      &.yellow {
        background-color: rgba(245, 206, 117, 0.05);

        .progress {
          background: linear-gradient(90deg, #f5ce75 0%, #eacb86 100%);
        }
      }
    }
    &__x {
      display: flex;
      justify-content: space-between;

      span {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: calc(100% + rem(16));
          left: 50%;
          transform: translate(-50%, 0);
          width: rem(1);
          height: rem(192);
          background-color: cl(opacity-light-10);
        }
      }
    }
  }
}
