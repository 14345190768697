@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.searchSliderNav_next,
.searchSliderNav_prev {
  &.swiper-button-disabled {
    display: none;
  }
}
