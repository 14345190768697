@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.select {
  position: relative;
  // z-index: 2;

  &.big {
    .selectTop {
      padding: rem(16);
      height: rem(50);
    }
  }

  &.border {
    .selectTop {
      background-color: transparent;
      border: rem(1) solid cl(opacity-light-10);
    }
  }

  &.open {
    .selectTop {
      background-color: cl(main-light);
      &__arrow {
        svg {
          transform: scale(1, -1);
          path {
            stroke: cl(main-dark);
          }
        }
      }
      &__icon {
        svg {
          path {
            stroke: cl(main-dark);
          }
        }
      }
      &__label {
        color: cl(main-dark);
      }
      &__count {
        background-color: cl(main-dark);
      }
      &__selected {
        color: cl(main-dark);
      }
    }

    .selectList,
    .selectRange {
      opacity: 1;
      visibility: visible;
      pointer-events: unset;
      user-select: unset;
      transform: translate(0);
    }

    &.isProjectList {
      .selectTop {
        background-color: transparent;

        &__arrow {
          svg {
            path {
              stroke: cl(main-light);
            }
          }
        }
        &__selected {
          color: cl(main-light);

          &.placeholder {
            color: cl(opacity-light-50);
          }
        }
      }
      .selectList {
        background: #212127;
      }
    }
  }

  &.isProjectList {
    .selectList {
      border-radius: rem(20);
      padding: rem(8);

      &__scroll {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: rem(2);
        padding: 0 rem(10) 0 0;
        overflow-y: auto;
        max-height: rem(172);

        &::-webkit-scrollbar {
          background-color: transparent;
          width: rem(4);
          height: rem(5);
          border-radius: rem(20);
        }

        &::-webkit-scrollbar-thumb {
          background-color: cl(main-light);
          border-radius: rem(20);
        }
      }
    }
  }

  &.dropdownRight {
    .selectList {
      left: auto;
      right: 0;
    }
    .selectRange {
      left: auto;
      right: 0;
    }
  }

  &Top {
    cursor: pointer;
    display: flex;
    width: 100%;
    gap: rem(6);
    align-items: center;
    padding: rem(16);
    padding: rem(12) rem(16);
    height: rem(40);
    border-radius: rem(16);
    background-color: cl(opacity-light-5);
    transition: all $time $tr;

    &__icon {
      width: rem(16);
      height: rem(16);

      svg {
        width: 100%;
        height: 100%;

        path {
          transition: all $time $tr;
        }
      }
    }
    &__label {
      transition: all $time $tr;
    }
    &__count {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: rem(4);
      background-color: cl(main-light);

      width: rem(16);
      height: rem(16);

      transition: all $time $tr;

      div {
        font-size: rem(9);
      }
    }
    &__selected {
      font-weight: 400;
      font-size: rem(12);
      line-height: 130%;
      color: cl(main-light);
      transition: all $time $tr;

      &.placeholder {
        color: cl(opacity-light-50);
      }
    }
    &__arrow {
      margin: 0 0 0 auto;
      width: rem(16);
      height: rem(16);

      svg {
        width: 100%;
        height: 100%;
        transition: all $time $tr;

        path {
          transition: all $time $tr;
        }
      }
    }
  }
  &List {
    position: absolute;
    z-index: 2;
    top: calc(100% + rem(8));
    left: 0;
    min-width: 100%;
    padding: rem(16);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12);
    border: rem(1) solid cl(main-light, 0.2);
    border-radius: rem(16);
    background: cl(main-light);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    transform: translate(0, rem(-12));

    transition: all $time $tr;

    @media (max-width: $tablet) {
      padding: rem(12);
    }

    .checkbox {
      white-space: nowrap;
    }

    &__item {
      font-weight: 400;
      font-size: rem(12);
      line-height: 130%;
      color: cl(main-dark);
      transition: all $time $tr;
      text-align: left;

      &:hover {
        color: cl(main-dark, 0.7);
      }
    }

    &__project {
      width: 100%;
      gap: rem(12);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(12);
      border-radius: rem(20);

      transition: all $time $tr;

      &:hover {
        background-color: cl(opacity-light-5);
      }

      &Address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(10);
        padding: rem(8) rem(12);
        border: rem(1) solid cl(opacity-light-10);
        border-radius: rem(12);

        .chain {
          display: flex;
          align-items: center;
          gap: rem(4);

          img {
            flex: 0 0 auto;
            width: rem(16);
            height: rem(16);
          }
        }
        .divider {
          width: rem(1);
          height: rem(16);
          background-color: cl(opacity-light-10);
          border-radius: rem(10);
        }
        .address {
          display: flex;
          align-items: center;
          gap: rem(4);

          button {
            flex: 0 0 auto;
            width: rem(12);
            height: rem(12);

            &:hover {
              svg {
                path {
                  stroke: cl(main-light);
                }
              }
            }

            svg {
              width: 100%;
              height: 100%;

              path {
                stroke: cl(opacity-light-50);
                transition: all $time $tr;
              }
            }
          }
        }
      }
    }
  }
  &Range {
    position: absolute;
    z-index: 2;
    top: calc(100% + rem(8));
    left: 0;
    min-width: rem(330);
    padding: rem(16);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(8);
    border: rem(1) solid cl(main-light, 0.2);
    border-radius: rem(16);
    background: cl(main-light);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    transform: translate(0, rem(-12));

    transition: all $time $tr;

    @media (max-width: $tablet) {
      padding: rem(12);
      width: 100%;
      min-width: rem(300);
      max-width: rem(330);
    }

    &__body {
      display: flex;
      gap: rem(8);

      &Item {
        flex: 0 0 calc(50% - rem(4));
      }
    }

    &__btns {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: rem(8);
    }
  }
}
