@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.info {
}

.score {
  display: flex;
  align-items: center;
  gap: rem(2);

  // &.right {
  //   justify-content: flex-end;
  // }

  img {
    flex: 0 0 auto;
    width: rem(16);
    height: rem(16);
    object-fit: contain;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(32) rem(24) rem(48);
  background-color: cl(opacity-light-5);
  border-radius: rem(24);
}
