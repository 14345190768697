@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.search {
  position: fixed;
  z-index: 10;
  inset: 0;
  padding: rem(50) 0;
  height: calc(100vh);
  overflow: auto;

  pointer-events: none;
  user-select: none;
  visibility: hidden;

  &.open {
    pointer-events: unset;
    user-select: unset;
    visibility: visible;

    .search {
      &__bg {
        opacity: 1;
      }
      &Top {
        opacity: 1;
      }
      &Body {
        opacity: 1;
        transform: translate(0);
      }
    }
  }

  .con {
    // display: flex;
  }

  &__bg {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(rem(20));
    background: cl(main-dark, 0.1);

    opacity: 0;

    transition: all $time $tr;
  }

  &Body {
    padding: rem(72) 0 0;
    margin: 0 auto;
    max-width: rem(700);
  }
}
