@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.panels {
  display: flex;
  flex-wrap: wrap;
  gap: rem(16);

  &__slider {
    width: 100%;
    overflow: visible;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(3);

    span {
      flex: 0 0 auto;
      display: block;
      width: rem(5);
      height: rem(5);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      transition: all $time $tr;
    }
  }
}
