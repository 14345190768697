@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.modal {
  display: flex;
  flex-direction: column;
  gap: rem(12);

  @media (max-width: $mobile) {
    padding: rem(32) 0 0;
  }

  &__switch {
    display: flex;
    gap: rem(5);
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(20);
    padding: rem(5);

    &Btn {
      flex: 0 0 calc(50% - rem(2.5));
    }
  }
  &__title {
  }
  &__icon {
    margin: rem(14) 0;
    height: rem(144);

    @media (max-width: $mobile) {
      margin: rem(6) 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .input {
  }
  &__forgot {
    align-self: flex-end;
    div {
      transition: all $time $tr;
    }

    &:hover {
      div {
        color: cl(main-light);
      }
    }
  }
  &__upload {
    margin: rem(-4) 0 0;
  }
  &__submit {
    margin: rem(-2) 0 0;
    align-self: flex-end;
  }
  &Social {
    display: flex;
    justify-content: center;
    gap: rem(8);
  }
  .button__space {
    margin: rem(24) 0 0;

    &Big {
      margin: rem(126) 0 0;

      @media (max-width: $tablet) {
        margin: rem(116) 0 0;
      }
    }
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(32);
    width: 100%;
    max-width: rem(270);
    margin: 0 auto;

    &__code {
      position: relative;
      display: flex;
      gap: rem(2);
      justify-content: space-between;

      input {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
      &Item {
        flex: 0 0 auto;
        width: rem(50) !important;
        height: rem(50) !important;

        svg {
          width: rem(8);
          height: rem(8);
        }
      }
    }
  }
}

.loader {
  position: relative;
  margin: 0 auto;
  width: rem(24);
  height: rem(24);
  border: rem(1) solid cl(opacity-light-10);
  border-radius: 50%;

  svg {
    position: absolute;
    z-index: 1;
    inset: rem(-1);

    animation: loaderAnim 1s infinite linear;
  }
}

@keyframes loaderAnim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
