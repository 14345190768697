@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.top {
  display: flex;
  gap: rem(16);

  @media (max-width: $mobile) {
    margin: 0 0 rem(48);
    display: flex;
    flex-direction: column;
  }

  &__search {
    flex: 0 0 rem(140);

    @media (max-width: $mobile) {
      flex: 1 1 auto;
    }
  }

  &Body {
    display: flex;
    flex-wrap: wrap;
    gap: rem(4);

    @media (max-width: $mobile) {
      gap: rem(2);
    }

    &__btn {
      background-color: cl(opacity-light-5) !important;

      &:hover {
        background-color: cl(main-light) !important;
      }
    }
  }
}
