@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.pagination {
  display: flex;
  gap: rem(4);
  padding: rem(4);
  border: rem(1) solid cl(opacity-light-10);
  border-radius: rem(12);

  @media (max-width: $tablet) {
    padding: rem(8);
  }

  &__nav {
    padding: 0 rem(12) 0 rem(16);
    font-weight: 400;
    font-size: rem(12);
    line-height: 130%;
    color: cl(main-light);
    transition: all $time $tr;

    @media (max-width: $tablet) {
      padding: 0;
      width: rem(32);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);
      }
    }

    &:hover {
      color: cl(main-light, 0.8);
    }
  }
}
