@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.boost {
  &Content {
    margin: rem(32) 0 0;
  }
}
