@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.contact {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__divider {
      margin: rem(12) 0;

      @media (max-width: $tablet) {
        margin: rem(4) 0;
      }
    }
  }
}
