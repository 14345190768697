// ===============
// colors palette
// ===============

$colors: (
  'blck': #141414,
  'whte': #fff,
  'main-dark': #010106,
  'main-light': #fff,
  'opacity-light-5': rgba(255, 255, 255, 0.05),
  'opacity-light-10': rgba(255, 255, 255, 0.1),
  'opacity-light-50': rgba(255, 255, 255, 0.5),
  'opacity-light-80': rgba(255, 255, 255, 0.8),
  'other-yellow': #ffc700,
  'other-red': #cb2929,
  'other-blue': #60a4e3,
  'other-green': #4ba865,
  'other-purple': #ad75f5,
);

//========================================================================================================================================================

:root {
  @if $colors {
    @each $key, $value in $colors {
      --color-#{$key}: #{$value};
      --color-#{$key}--rgb: #{hextorgb($value)};
    }
  }

  --font-primary: #{$fontFamily};
}
