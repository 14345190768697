@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.upload {
  display: flex;
  cursor: pointer;
  position: relative;
  input {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    inset: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &.uploaded {
    input {
      z-index: 1;
    }
  }

  &__btn {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  &Content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: rem(2);

    &__name {
      cursor: default;
    }

    &__remove {
      cursor: pointer;
      width: rem(16);
      height: rem(16);

      &:hover {
        svg {
          path {
            stroke-opacity: 1;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;

        path {
          transition: all $time $tr;
        }
      }
    }
  }
}
