@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.block {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    &__calendar {
      max-width: 100%;
    }
  }
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(12);
  backdrop-filter: blur(rem(200));
  background: rgba(255, 255, 255, 0.1);
  padding: rem(52) rem(16) rem(16);
  border-radius: rem(24);
  width: 100%;

  &__icon {
    position: relative;
    padding: rem(60) 0 rem(54);

    &.spot {
      padding: rem(12) 0 0;

      img {
        width: rem(148);
      }

      .banner__iconStars {
        width: rem(225);
      }
    }
    &.circle {
      padding: rem(18) 0 0;

      img {
        width: rem(220);
      }

      .banner__iconStars {
        width: rem(263);
      }
    }
    &.email {
      padding: rem(4) 0 0;

      img {
        width: rem(144);
      }
    }

    img {
      position: relative;
      z-index: 2;
      width: rem(108);
      height: auto;
      object-fit: contain;
    }

    &Stars {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(200);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__title {
    &.circle {
      margin: rem(50) 0 0;
    }
    &.spot {
      margin: rem(12) 0 0;
    }
    &.email {
      margin: rem(4) 0 0;
    }
  }
  &__upload {
    margin: rem(12) 0 0;
  }
  &__select {
    margin: rem(12) 0 0;
    width: 100%;
  }
  &__add {
    width: 100%;
    margin: rem(12) 0 0;
  }
}
