@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.faq {
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);
  padding: rem(24);

  @media (max-width: $mobile) {
    padding: rem(24) rem(16);
  }

  &__title {
  }
  &Body {
    margin: rem(12) 0 0;
    display: flex;
    flex-direction: column;
    gap: rem(8);

    @media (max-width: $mobile) {
      margin: rem(24) 0 0;
      padding: 0 rem(16);
    }
  }
}
