@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(8);
  padding: rem(12) rem(16);
  border: rem(1) solid cl(opacity-light-10);
  border-radius: rem(12);

  font-weight: 400;
  font-size: rem(12);
  line-height: 130%;
  color: cl(opacity-light-50);

  transition: all $time $tr;

  svg,
  img {
    flex: 0 0 auto;
    width: rem(16);
    height: rem(16);

    path {
      stroke-opacity: 0.5;
      transition: all $time $tr;
    }
  }

  &:hover {
    border-color: cl(opacity-light-50);
    color: cl(opacity-light-80);

    svg {
      path {
        stroke-opacity: 1;
      }
    }
  }

  &:active {
    background: rgba(255, 255, 255, 0.05);
  }

  &__icon {
  }

  &__label {
    padding: rem(2) rem(6);
    border-radius: rem(3);
    backdrop-filter: blur(rem(12));
    background: cl(other-green, 0.1);
    min-height: rem(16);

    font-weight: 500;
    font-size: rem(9);
    line-height: 130%;
    color: cl(other-green);
  }

  &.onlyIcon {
    padding: rem(12);
    width: rem(40);
    height: rem(40);

    &.sm {
      width: rem(32);
      height: rem(32);

      svg {
        width: rem(12);
        height: rem(12);
      }
    }
  }

  &.filled {
    // border: none;
    border-color: cl(main-light);
    background-color: cl(main-light);
    color: cl(main-dark);
    font-weight: 500;

    path {
      stroke-opacity: 1;
    }

    &:hover {
      background-color: #e1e1e1;
      border-color: #e1e1e1;
    }
    &:active {
      background-color: #bfbfbf;
      border-color: #e1e1e1;
    }
  }

  &.filledGrey {
    border: none;
    background-color: cl(opacity-light-10);
    color: cl(main-light);
    font-weight: 500;

    path {
      stroke-opacity: 1;
    }

    &:hover {
      background-color: #e1e1e1;
      color: cl(main-dark);
    }
    &:active {
      background-color: #bfbfbf;
      color: cl(main-dark);
    }
  }

  &.filledDark {
    border: none;
    background-color: #1a1a1e;
    color: cl(main-light);
    font-weight: 500;

    &:hover {
      background-color: #e1e1e1;
      color: cl(main-dark);

      path {
        stroke: cl(main-dark);
      }
    }
    &:active {
      background-color: #bfbfbf;
      color: cl(main-dark);

      path {
        stroke: cl(main-dark);
      }
    }
  }

  &.noBorder {
    background-color: transparent;
    border: none;
  }
  &.whiteText {
    color: cl(main-light);

    &:hover {
      color: cl(opacity-light-80);
    }
  }

  &.dark {
    color: cl(main-dark);

    &:hover {
      color: cl(main-dark, 0.7);
    }
  }

  &.withIcon {
    padding: 0;
    border: none;
    background-color: transparent;

    .button__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: cl(opacity-light-5);
      border-radius: rem(12);
      width: rem(32);
      height: rem(32);

      transition: all $time $tr;

      img {
        width: rem(16);
        height: rem(16);
      }
    }

    &:hover {
      .button__icon {
        background-color: cl(opacity-light-10);
      }
    }
  }

  &.sm {
    padding: rem(8) rem(16);
    border-radius: rem(10);
  }

  &.lg {
    padding: rem(16);
    border-radius: rem(16);
  }

  &.round {
    border-radius: 50%;
  }

  &.disabled {
    &:hover {
      border: rem(1) solid cl(opacity-light-10) !important;
    }
    svg {
      path {
        stroke-opacity: 0.1;
      }
    }
  }

  &.yellow {
    background-color: cl(other-yellow);
    color: cl(main-dark);
  }
  &.lightYellow {
    background-color: cl(other-yellow, 0.1);
    color: cl(other-yellow);
    border: none;

    &:hover {
      background-color: cl(other-yellow, 1);
      color: cl(main-dark);
    }
  }
  &.lightPurple {
    background-color: cl(other-purple, 0.1);
    border: none;
    color: cl(other-purple);

    &:hover {
      background-color: cl(other-purple, 1);
      color: cl(main-dark);
    }
  }
  &.blue {
    background-color: cl(other-blue);
    color: cl(main-dark);
  }
}
