@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.top {
  &Row {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);

    &:not(:first-child) {
      margin: rem(16) 0 0;
    }
    &__tabs {
      display: flex;
      overflow: visible;
      margin: 0 auto 0 0;

      @media (max-width: $tablet) {
        width: 100%;
      }

      & > div {
        & > div {
          width: auto !important;
        }
      }
    }
    &__search {
      width: 100%;
      max-width: rem(350);
      @media (max-width: $tablet) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
