@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  padding: rem(24);
  border-radius: rem(15);
  background-color: cl(opacity-light-5);

  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 100%;
    border-radius: rem(10);
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Content {
    margin: rem(16) 0 0;
    display: flex;
    flex-direction: column;

    &__tag {
      align-self: flex-start;
      padding: rem(2) rem(4);
      border-radius: rem(5);
      font-weight: 400;
      font-size: rem(12);
      line-height: 130%;

      &.purple {
        color: #7b53ed;
        background-color: rgba($color: #7b53ed, $alpha: 0.2);
      }

      &.yellow {
        color: #ffc700;
        background-color: rgba($color: #ffc700, $alpha: 0.2);
      }

      &.blue {
        color: #4791ff;
        background-color: rgba($color: #4791ff, $alpha: 0.2);
      }
      &.red {
        color: #ed5353;
        background-color: rgba($color: #ed5353, $alpha: 0.2);
      }
    }
    &__title {
      margin: rem(12) 0 0;
    }
    &__text {
      margin: rem(12) 0 0;
    }
    &__info {
      display: flex;
      align-items: center;
      gap: rem(12);
      margin: rem(16) 0 0;

      &Item {
        display: flex;
        align-items: center;
        gap: rem(4);

        svg {
          flex: 0 0 auto;
          width: rem(16);
          height: rem(16);
        }
      }
    }
  }
}
