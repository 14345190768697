@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.tag {
  display: flex;
  gap: rem(4);
  border-radius: rem(10);
  padding: rem(8) rem(12);
  background-color: cl(opacity-light-5);
}
