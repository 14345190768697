@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.roadmap {
  padding: rem(34) rem(24) rem(24);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);

  @media (max-width: $mobile) {
    padding: rem(24) rem(16);
  }

  &Body {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);
    margin: rem(32) 0 0;

    @media (max-width: $mobile) {
      margin: rem(24) 0 0;
      padding: 0 rem(16);
    }

    &__item {
      flex: 0 1 calc(33.333% - rem(5.33));
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: rem(8);
      padding: rem(10);
      border: rem(1) solid cl(opacity-light-10);
      border-radius: rem(20);
      transition: all $time $tr;

      @media (max-width: $pc) {
        flex: 1 1 calc(33.333% - rem(5.33));
      }
      @media (max-width: $mobile) {
        flex: 1 1 100%;
      }

      &:hover {
        border: rem(1) solid cl(opacity-light-50);
        .roadmapBody__itemShow {
          svg {
            path {
              stroke-opacity: 1;
            }
          }
        }
      }

      &Icon {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(12);
        width: rem(32);
        height: rem(32);
        background-color: cl(opacity-light-5);
      }
      &Content {
        display: flex;
        flex-direction: column;
        gap: rem(2);
        flex: 1 1 auto;
      }
      &Title {
      }
      &Label {
        font-size: rem(9);

        &.completed {
          color: #78e053;
        }
      }
      &Show {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);

        svg {
          path {
            transition: all $time $tr;
          }
        }
      }
    }
  }
}
