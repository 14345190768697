@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.popular {
  margin: rem(36) 0 0;

  @media (max-width: $tablet) {
    margin: rem(32) 0 0;
  }

  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__nav {
      display: flex;
      align-items: center;
      gap: rem(8);
    }
  }

  &__title {
  }
  &Cards {
    margin: rem(24) 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }

    &__item {
      flex: 0 0 calc(25% - rem(12));

      @media (max-width: $tablet) {
        flex: 0 0 calc(33.333% - rem(10.66));
      }
      @media (max-width: $mobile) {
        flex: 0 0 calc(100%);
      }
    }
  }
}
