@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.report {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    margin: rem(24) auto 0;
    max-width: rem(650);
  }
}

.bottom {
  margin: rem(24) 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    a {
      color: cl(main-light);
      transition: all $time $tr;

      &:hover {
        color: cl(opacity-light-80);
      }
    }
  }
  &__btn {
  }
}
