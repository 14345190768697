@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.faq {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__item {
      display: flex;
      flex-direction: column;
      gap: rem(12);

      &:not(:first-child) {
        padding: rem(12) 0 0;
        border-top: rem(1) dashed cl(opacity-light-10);
        stroke-dasharray: 5;
      }
    }

    &__add {
      display: flex;
      gap: rem(4);
      margin: rem(4) 0 0;

      button {
        div {
          transition: all $time $tr;
        }
        &:hover {
          div {
            color: cl(opacity-light-80);
          }
        }
      }
    }
  }
}
