@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.progress {
  cursor: pointer;
  position: fixed;
  z-index: 9;
  bottom: rem(16);
  right: rem(16);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: 50%;
  padding: rem(10);
  width: 100%;
  max-width: rem(60);

  transition: all $time $tr;

  &.open {
    max-width: calc(100% - rem(32));
    border-radius: rem(24);
    padding: rem(16);
    .progress {
      &Body {
        &__chart {
          --b: 10px;
          --w: 88px;

          &Title {
            opacity: 1;
          }
        }
        &__categories {
          display: flex;
        }
        &__collapse {
          display: flex;
        }
      }
    }
  }

  &Body {
    display: flex;
    align-items: center;
    gap: rem(24);

    &__chart {
      --p: 0;
      --b: 5px;
      --w: 40px;
      --c: #337ab7;

      flex: 0 0 var(--w);
      width: var(--w);
      aspect-ratio: 1;
      position: relative;
      display: inline-grid;
      place-content: center;

      transition: all $time $tr;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
      }
      &:before {
        inset: 0;
        background: radial-gradient(farthest-side, cl(other-green) 98%, #0000)
            top/var(--b) var(--b) no-repeat,
          conic-gradient(
            cl(other-green) calc(var(--p) * 1%),
            cl(opacity-light-5) 0
          );
        mask: radial-gradient(
          farthest-side,
          #0000 calc(99% - var(--b)),
          #000 calc(100% - var(--b))
        );
      }
      &:after {
        inset: calc(50% - var(--b) / 2);
        background: cl(other-green);
        transform: rotate(calc(var(--p) * 3.6deg))
          translateY(calc(50% - var(--w) / 2));
      }

      /* Анимация */
      @property --p {
        syntax: '<number>';
        inherits: true;
        initial-value: 0;
      }
      .pieanimate {
        animation: p 1s 0.5s both;
      }
      @keyframes p {
        from {
          --p: 0;
        }
      }

      &Title {
        opacity: 0;
        transition: all $time $tr;
      }
    }
    &__categories {
      display: flex;
      flex-direction: column;
      gap: rem(8);
      margin: 0 auto 0 0;

      display: none;

      transition: all $time $tr;
    }
    &__collapse {
      cursor: pointer;
      margin: 0 0 0 rem(-4);

      display: none;

      transition: all $time $tr;

      svg {
        transition: all $time $tr;
      }

      &:hover {
        svg {
          opacity: 0.5;
        }
      }
    }
  }
}

.category {
  display: flex;
  gap: rem(8);

  &__progress {
  }
  &__name {
  }
}
