@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.details {
  &__text {
    margin: rem(-12) 0 0;
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);
    margin: rem(24) 0 0;
  }
}
