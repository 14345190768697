@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.dictionary {
  &Body {
    margin: rem(16) 0 0;
    padding: rem(24);
    border-radius: rem(24);
    background: cl(opacity-light-5);

    @media (max-width: $mobile) {
      padding: rem(16);
    }
  }
}
