@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.article {
  margin: rem(32) auto 0;

  @media (max-width: $tablet) {
    margin: 0 auto;
  }

  &Body {
    max-width: rem(930);
    margin: 0 auto;
    &__title {
    }
  }
  &__tags {
    margin: rem(24) auto 0;
    @media (max-width: $tablet) {
      margin: rem(16) auto 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    margin: rem(24) 0 0;
    padding: rem(32);
    border-radius: rem(24);
    background-color: cl(opacity-light-5);
    color: white;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
      padding: rem(16);
    }

    .text {
      display: flex;
      flex-direction: column;
      // margin: rem(16) 0 0;
      margin: rem(-4) 0 0;
      gap: rem(16);

      span {
        color: cl(main-light);
      }

      a {
        text-decoration: underline;
        text-decoration-skip-ink: none;
        color: cl(main-light);

        &:hover {
          text-decoration: none;
        }
      }
    }
    .space {
      // margin: rem(100) 0 0;
      margin: rem(52) 0 0;

      @media (max-width: $tablet) {
        margin: 0;
      }
    }
  }
}
