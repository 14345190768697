@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.input {
  padding: rem(12) rem(16);
  display: flex;
  align-items: center;
  gap: rem(8);
  border-radius: rem(16);
  // height: rem(40);
  background-color: cl(opacity-light-5);

  &.big {
    padding: rem(16);
  }

  &.border {
    background-color: transparent;
    border: rem(1) solid cl(opacity-light-10);
  }

  &__icon {
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__clear {
    cursor: pointer;
    width: rem(16);
    height: rem(16);

    display: none;

    &.visible {
      display: block;
    }

    &:hover {
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }

  textarea {
    width: 100%;
    height: rem(100);
    background-color: transparent;
    resize: none;

    font-weight: 400;
    font-size: rem(12);
    line-height: 130%;
    color: cl(main-light);

    &::placeholder {
      color: cl(opacity-light-50);
    }
  }
}
