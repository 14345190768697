@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.new {
  display: flex;
  gap: rem(16);

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: rem(32);
  }

  &Cards {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);

    &__item {
      flex: 0 0 calc(33.333% - rem(10.66));
      @media (max-width: $mobile) {
        flex: 1 1 calc(100%);
      }
    }

    &__text {
      flex: 1 1 calc(33.333% - rem(10.66));
      display: flex;
      flex-direction: column;
      gap: rem(7);
      padding: rem(24);

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
  &Purchase {
    // flex: 0 1 rem(500);
    flex: 1 1 auto;
    width: 100%;
    max-width: rem(500);

    @media (max-width: $tablet) {
      max-width: 100%;
    }
  }
}
