@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  position: relative;
  z-index: 1;
  padding: rem(16);
  border-radius: rem(20);

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: rem(20);
    padding: rem(1);

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  // white
  background: rgba(#ffffff, 0.05);

  &::before {
    background: linear-gradient(
      150deg,
      rgba(#ffffff, 0.5) 0,
      rgba(#fff, 0.27) 41%,
      rgba(#000, 1) 100%
    );
  }

  &.yellow {
    background: rgba(235, 171, 46, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#ebab2e, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.blue {
    background: rgba(#5f97cb, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#5f97cb, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.blue2 {
    background: rgba(#0085ff, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#0085ff, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.red {
    background: rgba(#cb5f6c, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#cb5f6c, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.grey {
    background: rgba(#73887a, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#73887a, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.green {
    background: rgba(#88cb5f, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#88cb5f, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }
  &.pink {
    background: rgba(#ac48a2, 0.05);

    &::before {
      background: linear-gradient(
        150deg,
        rgba(#ac48a2, 0.5) 0,
        rgba(#fff, 0.27) 41%,
        rgba(#000, 1) 100%
      );
    }
  }

  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
    }
    &__btn {
    }
  }
  &Content {
    margin: rem(22) 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(4);

    &.news {
    }
  }
}

.row {
  display: flex;
  gap: rem(8);
  justify-content: space-between;

  border-radius: rem(12);
  padding: rem(8);
  overflow: hidden;
  width: 100%;

  transition: all $time $tr;

  &:not(:first-child) {
    margin: rem(-2) 0 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.value {
  display: flex;
  align-items: center;
  gap: rem(2);

  img {
    width: rem(16);
    height: rem(16);
    object-fit: contain;
  }
}

.timer {
  display: flex;
  gap: rem(3);
  align-items: center;

  font-weight: 400;
  font-size: rem(9);
  line-height: 130%;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);

  &__item {
    padding: rem(2) rem(5);
    border-radius: rem(3);
    backdrop-filter: blur(rem(12));
    background: rgba(255, 255, 255, 0.1);
  }
}

.news {
  width: 100%;
  display: flex;
  align-items: center;
  gap: rem(8);
  padding: rem(16) rem(12);
  border-radius: rem(12);
  background: rgba(255, 255, 255, 0.05);

  transition: all $time $tr;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:not(:first-child) {
    margin: rem(4) 0 0;
  }

  &__img {
    flex: 0 0 auto;
    width: rem(32);
    height: rem(32);
    border-radius: rem(12);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Content {
    width: calc(100% - rem(40));

    &__title {
    }
    &__text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: rem(4);
  padding: 0 rem(42) 0 0;

  span {
    padding: rem(4) rem(10);
    border-radius: rem(8);
    background: rgba(255, 255, 255, 0.1);

    transition: all $time $tr;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}
