@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.table {
  margin: rem(24) 0 0;

  &__item {
    &Side {
      table {
        td {
          &:nth-child(1) {
            width: 0;
            padding: rem(16) 0 rem(16) rem(18) !important;
          }
          &:nth-child(2) {
            // padding: rem(16) rem(0);
          }
        }
      }
    }
    table {
      td {
        &:nth-child(1),
        &:nth-child(2) {
          width: 0;
          padding: rem(16) rem(18);
        }
        &:nth-child(2) {
          padding: rem(16) rem(0);
        }
      }
    }
  }
}

.favorite {
}

.type {
  display: inline-flex;
  justify-content: flex-start;
  border-radius: rem(3);
  padding: rem(2) rem(4);
  backdrop-filter: blur(12px);
  font-weight: 500;
  font-size: rem(9);
  line-height: 130%;

  &.green {
    color: #4ba865;
    background: rgba(#4ba865, 0.1);
  }
  &.yellow {
    color: #ffc700;
    background: rgba(#ffc700, 0.1);
  }
  &.red {
    color: #cb2929;
    background: rgba(#cb2929, 0.1);
  }
}

.chain {
  display: flex;
  gap: rem(5);

  font-weight: 400;
  font-size: rem(12);
  line-height: 130%;
  text-align: right;
  color: cl(opacity-light-50);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(3);
    width: rem(16);
    height: rem(16);
    background-color: cl(opacity-light-5);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
