@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.table {
  width: 100%;
  border-radius: rem(24);
  background: cl(opacity-light-5);
  border-collapse: collapse;

  &Wrapper {
    display: flex;
    // align-items: flex-start;
    border-radius: rem(0);
    background: transparent;

    &.withSide {
      position: relative;
      z-index: 1;
      border-radius: rem(24);
      background: cl(opacity-light-5);

      td {
        white-space: nowrap;
      }

      .table {
        background: transparent;
        border-radius: 0;

        &Col {
          height: rem(80);
        }
        Row {
          // flex: 1 1 auto;
        }
      }
    }
  }

  &Scroll {
    width: 100%;
    position: relative;
    overflow: auto;
    margin: 0 0 rem(8);

    &.scrolled {
      border-radius: rem(24);
    }

    &::-webkit-scrollbar {
      background-color: cl(opacity-light-5);
      height: rem(5);
      border-radius: rem(20);
    }

    &::-webkit-scrollbar-thumb {
      background-color: cl(main-light);
      border-radius: rem(20);
      height: 100%;
      border: rem(1) solid transparent;
    }
    &::-webkit-scrollbar-button:horizontal:start:decrement {
      // display: block;
      // width: rem(24);
    }

    &::-webkit-scrollbar-button:horizontal:end:increment {
      // display: block;
      // width: rem(24);
      // background-color: red;
    }
  }

  &Head {
    .tableCol {
      // border-bottom: rem(1) solid cl(opacity-light-10);
      border-bottom: rem(1) solid #26262a;
    }
  }
  &Side {
    position: relative;
    z-index: 1;
    // display: flex;
    // // flex-direction: column;
    // flex-wrap: wrap;

    border-collapse: collapse;
    &::before {
      content: '';
      position: absolute;
      left: calc(100% + rem(1));
      top: 0;
      bottom: 0;
      width: rem(45);
      background: linear-gradient(90deg, #111116 0%, rgba(17, 17, 22, 0) 100%);
    }

    tr {
      &:first-child {
        td {
          border-bottom: rem(1) solid #26262a;
        }
      }
    }

    &__wrapper {
      border-right: rem(1) solid #26262a;
    }

    .tableCol {
      flex: 1 1 auto;
      height: rem(80);
      // width: 100%;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      padding: rem(12) rem(18);
      &:first-child {
        // width: 100%;
        padding: rem(12) rem(18);
        // border-bottom: rem(1) solid cl(opacity-light-10);
        // border-bottom: rem(1) solid #26262a;
      }
      &:last-child {
        // width: 100%;
        padding: rem(12) rem(18);
        // border-bottom: none;
      }
      // height: rem(80);
    }
  }
  &Body {
  }
  &Col {
    padding: rem(16) rem(32);

    font-weight: 400;
    font-size: rem(12);
    line-height: 130%;
    color: cl(opacity-light-50);

    @media (max-width: $tablet) {
      padding: rem(16) rem(12);

      &:first-child {
        padding: rem(16) rem(24);
      }
      &:last-child {
        padding: rem(16) rem(24);
      }
    }

    &:last-child {
      width: 0;
    }

    &__div {
      display: flex;
      align-items: center;
      gap: rem(2);

      &.center {
        justify-content: center;
      }
      &.right {
        justify-content: flex-end;
        transform: translate(-50%, 0);

        @media (max-width: $tablet) {
          transform: translate(0);
        }
      }
    }

    &__sort {
      width: rem(12);
      height: rem(12);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .div {
      // display: flex;

      // &.center {
      //   justify-content: center;
      // }
      // &.right {
      //   justify-content: flex-end;
      //   transform: translate(-50%, 0);

      //   @media (max-width: $tablet) {
      //     transform: translate(0);
      //   }
      // }
    }
  }
  &Row {
  }
}
