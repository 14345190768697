@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.bottom {
  display: flex;
  gap: rem(16);
  margin: rem(26) 0 0;

  @media (max-width: $tablet) {
    margin: rem(16) 0 0;
    flex-wrap: wrap;
  }

  &Col {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: rem(16);

    &:nth-child(1) {
      flex: 1 1 rem(890);
      @media (max-width: $tablet) {
        flex: 1 1 100%;
      }
    }
    &:nth-child(2) {
      flex: 1 1 rem(436);
      @media (max-width: $tablet) {
        flex: 1 1 100%;
      }
    }

    @media (max-width: $tablet) {
      flex: 1 1 100%;
    }
  }
  &Row {
    display: flex;
    gap: rem(16);

    @media (max-width: $mobile) {
      flex-wrap: wrap;
    }
  }
  &Card {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 calc(50% - rem(8));
    padding: rem(50) rem(30);
    border-radius: rem(20);

    @media (max-width: $mobile) {
      padding: rem(32) rem(45);
      flex: 1 1 100%;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      border-radius: rem(20);
      padding: rem(1);

      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &.yellow {
      background: rgba(#ebab2e, 0.05);

      &::before {
        background: linear-gradient(
          150deg,
          rgba(#ebab2e, 0.5) 0,
          rgba(#fff, 0.27) 41%,
          rgba(#000, 1) 100%
        );
      }
    }
    &.blue {
      background: rgba(#5f97cb, 0.05);

      &::before {
        background: linear-gradient(
          150deg,
          rgba(#5f97cb, 0.5) 0,
          rgba(#fff, 0.27) 41%,
          rgba(#000, 1) 100%
        );
      }
    }

    &__icon {
      height: rem(40);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    &__title {
      margin: rem(24) 0 0;

      @media (max-width: $mobile) {
        margin: rem(24) 0 0;
      }
    }
    &__text {
      margin: rem(12) 0 0;
      max-width: rem(240);
    }
    &__btn {
      margin: rem(16) 0 0;
    }
  }

  &Text {
    padding: rem(32);
    background: rgba(255, 255, 255, 0.05);
    border-radius: rem(20);

    @media (max-width: $mobile) {
      padding: rem(16);
    }

    &__item {
      &:not(:first-child) {
        margin: rem(32) 0 0;
        padding: rem(32) 0 0;
        border-top: rem(1) solid rgba(255, 255, 255, 0.1);

        @media (max-width: $mobile) {
          margin: rem(16) 0 0;
          padding: rem(16) 0 0;
        }
      }

      &Title {
      }
      &Content {
        margin: rem(12) 0 0;
      }
    }
  }

  &Links {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    height: 100%;

    @media (max-width: $mobile) {
      gap: rem(12);
    }

    &__item {
      cursor: pointer;
      flex: 1 1 auto;
      display: flex;
      gap: rem(12);
      align-items: center;
      padding: rem(20) rem(32);
      background: rgba(255, 255, 255, 0.05);
      border-radius: rem(20);
      transition: all $time $tr;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        .bottomLinks__itemArrow {
          border-color: rgba(255, 255, 255, 0.15);
        }
      }

      @media (max-width: $mobile) {
        padding: rem(16);
      }

      &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(40);
        height: rem(40);
        background: rgba(255, 255, 255, 0.05);
        border-radius: rem(12);

        img {
          width: rem(16);
          height: rem(16);
          object-fit: contain;
        }
      }
      &Title {
      }
      &Arrow {
        margin: 0 0 0 auto;
      }
    }
  }
}
