@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.row {
  display: flex;
  align-items: center;
  padding: rem(12);
  border-radius: rem(20);

  transition: all $time $tr;

  &:hover {
    background-color: cl(opacity-light-5);
  }

  &__icon {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Name {
    margin: 0 0 0 rem(8);

    &__title {
    }
    &__label {
    }
  }
  &Address {
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(12);
    padding: rem(8) rem(12);

    @media (max-width: $mobile) {
      display: none;
    }

    &__network {
      display: flex;
      gap: rem(4);
      align-items: center;

      svg {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);
      }
    }
    &__item {
      display: flex;
      gap: rem(4);
      align-items: center;

      margin: 0 0 0 rem(10);

      &::before {
        content: '';
        width: rem(2);
        height: rem(10);
        background-color: cl(opacity-light-10);
        margin: 0 rem(10) 0 0;
      }

      button {
        width: rem(12);
        height: rem(12);

        &:hover {
          svg {
            path {
              stroke-opacity: 1;
            }
          }
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all $time $tr;
          }
        }
      }
    }
  }
  &__rate {
    display: flex;
    gap: rem(4);
    align-items: center;

    @media (max-width: $mobile) {
      margin: 0 0 0 auto;
    }

    img {
      width: rem(12);
      height: rem(12);
      object-fit: cover;
    }
  }
}
