@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.modal {
  position: fixed;
  z-index: 11;
  inset: 0;
  height: 100vh;

  &.open {
  }
  &.big {
    .modalContent {
      padding: rem(32) rem(96);
      max-width: rem(650);
    }
  }
  &.withCalendar {
    .con {
      padding: rem(110) rem(16);
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    .modalBody {
      min-height: auto;
      gap: rem(18);
    }
    .modal__calendar {
      align-self: flex-start;
      max-width: rem(400);
    }
  }

  .con {
    height: 100vh;
    overflow: auto;
    padding: rem(24) rem(16);
  }
  &__bg {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(rem(48));
    background: rgba(1, 1, 6, 0.08);
  }
  &Body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  &Content {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(rem(100));
    background: rgba(255, 255, 255, 0.05);
    padding: rem(32) rem(64);
    border-radius: rem(24);
    width: 100%;
    max-width: rem(500);

    @media (max-width: $mobile) {
      padding: rem(24) rem(16);
    }

    &__back {
      cursor: pointer;
      position: absolute;
      top: rem(16);
      left: rem(16);
      width: rem(24);
      height: rem(24);

      &:hover {
        svg {
          path {
            stroke-opacity: 1;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
        path {
          transition: all $time $tr;
        }
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: rem(16);
      right: rem(16);
      width: rem(24);
      height: rem(24);

      &:hover {
        svg {
          path {
            stroke-opacity: 1;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
        path {
          transition: all $time $tr;
        }
      }
    }
  }
}
