@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.community {
  margin: rem(32) 0 0;

  &Body {
    width: 100%;
    margin: rem(24) 0 0;
    display: flex;
    gap: rem(16);
    height: rem(455);

    @media (max-width: $mobile) {
      margin: rem(16) 0 0;
      height: auto;
      gap: rem(8);
      flex-direction: column;
    }

    &__col {
      flex: 1 1 rem(890);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: rem(24);

      border-radius: rem(20);
      backdrop-filter: blur(rem(100));
      background: cl(opacity-light-5);

      @media (max-width: $tablet) {
        flex: 0 0 calc(50% - rem(8));
      }

      @media (max-width: $mobile) {
        flex: 1 1 auto;
        height: rem(240);
      }

      &:nth-child(2) {
        flex: 1 1 rem(437);

        @media (max-width: $tablet) {
          flex: 0 0 calc(50% - rem(8));
        }
        @media (max-width: $mobile) {
          flex: 1 1 auto;
        }
      }

      &Icon {
        width: rem(32);
        height: rem(32);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &Title {
        margin: rem(16) 0 0;
      }
      &Text {
        margin: rem(4) 0 0;
      }
    }
  }
}
