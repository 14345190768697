@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.info {
  display: flex;
  &__img {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Content {
    margin: 0 0 0 rem(8);
    &__name {
    }
    &__label {
      text-align: left;
    }
  }
}
