@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.info {
  margin: rem(32) 0 0;

  @media (max-width: $tablet) {
    margin: rem(16) 0 0;
  }

  &Body {
    display: flex;
    width: 100%;
    gap: rem(16);

    @media (max-width: $tablet) {
      flex-wrap: wrap;
      gap: rem(8);
    }

    &__col {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      width: calc(100% - rem(516));
      flex: 1 1 100%;

      @media (max-width: $tablet) {
        gap: rem(8);
      }

      &:nth-child(2) {
        flex: 0 0 rem(500);

        @media (max-width: $pc) {
          flex: 0 0 rem(350);
        }
        @media (max-width: $tablet) {
          flex: 1 1 auto;
        }
      }
    }

    &__row {
      display: flex;
      gap: rem(16);

      @media (max-width: $pc) {
        flex-direction: column;
      }

      @media (max-width: $tablet) {
        gap: rem(8);
      }

      &Col {
        flex: 0 0 calc(50% - rem(8));
        display: flex;
        flex-direction: column;
        gap: rem(16);

        @media (max-width: $pc) {
          flex: 1 1 100%;
          flex-direction: row;
        }

        @media (max-width: $tablet) {
          flex: 1 1 auto;
          gap: rem(8);
        }
      }
    }
  }
}

.card {
  cursor: pointer;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: rem(4);
  justify-content: center;
  align-items: center;

  border-radius: rem(20);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);

  @media (max-width: $pc) {
    flex: 0 0 calc(50% - rem(8));
    padding: rem(36) rem(24);
  }

  @media (max-width: $tablet) {
    flex: 0 0 calc(50% - rem(4));
  }

  &:hover {
    .card__icon {
      svg {
        path {
          stroke: cl(main-light);
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: rem(16);
    right: rem(16);
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }
  &__value {
  }
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(2);

    svg {
      flex: 0 0 auto;
      width: rem(12);
      height: rem(12);
    }
  }
}
