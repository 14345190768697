@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.projects {
  margin: rem(24) 0 0;

  &__title {
  }
  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Body {
    margin: rem(16) 0 0;
    display: flex;
    gap: rem(16);

    @media (max-width: $tablet) {
      flex-wrap: wrap;
    }
  }
}

.card {
  flex: 0 0 calc(25% - rem(12));
  padding: rem(24);
  display: flex;
  flex-direction: column;
  gap: rem(16);
  border-radius: rem(24);
  background-color: cl(opacity-light-5);

  @media (max-width: $tablet) {
    flex: 1 1 100%;
  }

  &Profile {
    display: flex;
    align-items: center;
    gap: rem(8);

    &__img {
      overflow: hidden;
      border-radius: 50%;
      width: rem(38);
      height: rem(38);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;

      &Name {
        display: flex;
        gap: rem(4);

        .chain {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: rem(5);
          width: rem(16);
          height: rem(16);
          flex: 0 0 auto;
          width: rem(16);
          height: rem(16);
          background-color: cl(opacity-light-5);

          svg {
            width: rem(8.4);
            height: rem(8.4);
          }
        }
      }
      &Label {
      }
    }
  }
  &Stats {
    display: flex;
    gap: rem(4);
    align-items: flex-end;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      gap: rem(4);

      img {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);
        object-fit: contain;
      }
    }
  }
  &Top {
    display: flex;
    justify-content: space-between;
  }
  &__address {
  }
  &Body {
    display: flex;
    gap: rem(12);

    &__item {
      flex: 0 0 calc(50% - rem(6));
      display: flex;
      gap: rem(16);
      flex-direction: column;
      align-items: center;
      border: rem(1) solid cl(opacity-light-10);
      border-radius: rem(20);
      padding: rem(8);

      &.yellow {
        .cardBody__itemIcon {
          background: cl(other-yellow, 0.1);
          img {
            height: rem(20);
          }
        }
      }
      &.purple {
        .cardBody__itemIcon {
          background: cl(other-purple, 0.1);
        }
      }

      &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(40);
        height: rem(40);
        border-radius: rem(12);

        img {
          width: auto;
          height: rem(14);
          object-fit: contain;
        }
      }
      &Value {
      }
      &Label {
        margin: rem(-12) 0 0;
      }
      &Btn {
        width: 100%;
      }
    }
  }
}
