@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.modals {
  &Body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(16);

    max-width: rem(700);
    margin: rem(24) auto;
  }
}
