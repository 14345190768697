@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.promoted {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin: rem(36) 0 0;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }
  }

  &Top {
    display: flex;
    flex-wrap: wrap;
    gap: rem(24);
    justify-content: space-between;
    align-items: center;

    &__btns {
      display: flex;
      gap: rem(8);
      align-items: center;

      @media (max-width: $tablet) {
        flex-wrap: wrap;
      }

      &Btn {
        color: cl(main-light);
      }

      &Item {
        @media (max-width: $tablet) {
          flex: 0 0 calc(50% - rem(4));
        }

        .selectItem__icon {
          display: flex;
          align-items: center;
          gap: rem(4);

          img {
            flex: 0 0 auto;
            width: rem(12);
            height: rem(12);
            object-fit: contain;
          }
        }
      }

      &Row {
        margin: rem(8) 0 0;
        flex: 1 1 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
        }
      }

      &Listings {
        display: flex;
        align-items: center;
        gap: rem(12);
        padding: rem(3) rem(4) rem(3) rem(16);
        border: rem(1) solid cl(opacity-light-10);
        border-radius: rem(12);

        @media (max-width: $tablet) {
          flex: 0 0 calc(50% - rem(4));
        }

        .list {
          display: flex;
          gap: rem(2);
          margin: 0 0 0 auto;

          label {
            position: relative;
            input {
              cursor: pointer;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;

              &:checked + .list__btn {
                background-color: cl(main-light, 0.2);
              }
            }
          }
        }
      }
    }
  }

  &__table {
    margin: rem(16) 0 0;

    .head__row {
      display: flex;
      align-items: center;
      gap: rem(2);

      svg {
        flex: 0 0 auto;
        width: rem(12);
        height: rem(12);
      }
    }

    td {
      white-space: nowrap;
      padding: rem(16) rem(12);
      &:nth-child(1),
      &:nth-child(2) {
        width: 0;
        padding: rem(16) rem(16) rem(16) rem(24);
      }
      &:nth-child(2) {
        padding: rem(16) rem(0);

        @media (max-width: $tablet) {
          width: auto;
        }
      }
      &:last-child {
        width: 0;
        padding: rem(16) rem(24) rem(16) rem(12);
      }
    }
  }

  &__pagination {
    margin: rem(32) auto 0;
  }
}

.type {
  display: inline-flex;
  justify-content: flex-start;
  border-radius: rem(3);
  padding: rem(2) rem(4);
  backdrop-filter: blur(12px);
  font-weight: 500;
  font-size: rem(9);
  line-height: 130%;

  &.green {
    color: #4ba865;
    background: rgba(#4ba865, 0.1);
  }
  &.yellow {
    color: #ffc700;
    background: rgba(#ffc700, 0.1);
  }
  &.red {
    color: #cb2929;
    background: rgba(#cb2929, 0.1);
  }
  &.blue {
    color: #4791ff;
    background: rgba(#4791ff, 0.1);
  }
  &.purple {
    color: #7b53ed;
    background: rgba(#7b53ed, 0.1);
  }
}

.chains {
  display: flex;
  gap: rem(2);
  flex-direction: column;
  align-items: flex-start;
}

.chain {
  display: flex;
  align-items: center;
  gap: rem(5);

  font-weight: 400;
  font-size: rem(12);
  line-height: 130%;
  text-align: left;
  color: cl(opacity-light-50);

  &.reverse {
    flex-direction: row-reverse;
  }
  &.smallIcon {
    .chain__icon {
      width: rem(12);
      height: rem(12);
    }
  }
  &.transparentIcon {
    .chain__icon {
      background-color: transparent;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(3);
    width: rem(16);
    height: rem(16);
    background-color: cl(opacity-light-5);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.diff {
  &.plus {
    color: cl(other-green);
  }
  &.minus {
    color: cl(other-red);
  }
}
