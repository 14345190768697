@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.top {
  &Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &Body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem(16) 0 0;

    &__btns {
      display: flex;
      overflow: visible;
      margin: 0 auto 0 0;
      // gap: rem(8);

      // @media (max-width: $tablet) {
      //   gap: 0;
      // }

      & > div {
        & > div {
          width: auto !important;
        }
      }
    }
    &__read {
      display: flex;
      gap: rem(3);

      a {
        &:hover {
          div {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
