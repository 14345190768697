@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.heading {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__top {
      display: flex;
      align-items: center;
      gap: rem(12);

      button {
        flex: 0 0 auto;
        width: rem(24);
        height: rem(24);
      }
    }
  }
}
