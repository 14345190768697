@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: rem(8);
}
