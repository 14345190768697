@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.coin {
  &Body {
    margin: rem(24) 0 0;
    display: flex;
    align-items: flex-start;
    gap: rem(16);

    &__content {
      margin: 0 0 0 auto;
      flex: 0 0 calc(50% - rem(10.666));

      @media (max-width: $tablet) {
        flex: 1 1 100%;
      }
    }

    &__side {
      position: sticky;
      top: rem(16);
      flex: 0 0 calc(25% - rem(10.666));

      @media (max-width: $tablet) {
        flex: 1 0 rem(250);
      }
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}

.bottom {
  margin: rem(24) 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    a {
      color: cl(main-light);
      transition: all $time $tr;

      &:hover {
        color: cl(opacity-light-80);
      }
    }
  }
  &__btn {
  }
}