@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.calendar {
  display: flex;
  flex-direction: column;
  gap: rem(16);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-10);
  border-radius: rem(20);
  padding: rem(32);

  max-width: rem(500);

  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__btns {
      display: flex;
      gap: rem(4);
    }
  }
  &Week {
    display: flex;
    flex-wrap: wrap;

    .calendar__day {
      text-align: center;
    }
  }
  &Body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem(12) 0;
  }
  &__day {
    flex: 0 0 calc(14.28%);
    display: flex;
    justify-content: center;

    button {
      gap: 0;
    }
  }
  &Bottom {
    margin: rem(16) 0 0;
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__row {
      display: flex;
      justify-content: space-between;

      &Item {
        display: flex;
        gap: rem(4);
      }
    }
  }
}
