@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.text {
  font-weight: 400;
  font-size: rem(12);
  line-height: 130%;
  color: cl(main-light);

  &._14 {
    font-size: rem(14);

    @media (max-width: $tablet) {
      font-size: rem(12);
    }
  }

  &.middle {
    font-weight: 500;
  }
  &.semiBold {
    font-weight: 600;
  }

  &.grey {
    color: cl(opacity-light-50);
  }
  &.black {
    color: cl(main-dark);
  }

  &.center {
    text-align: center;
  }
}
