@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.watchlist {
  &Top {
    display: flex;
    gap: rem(4);
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-child(3) {
        margin: rem(12) 0 0;
      }

      &Filter {
        display: flex;
        gap: rem(8);
      }
    }
    &__title {
    }
    &__share {
    }
  }

  &Body {
    margin: rem(16) 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(64) rem(0) rem(90);
    border-radius: rem(24);
    background-color: cl(opacity-light-5);
    overflow: hidden;

    @media (max-width: $mobile) {
      padding: rem(48) rem(0);
    }

    &__list {
    }
    &__title {
      margin: rem(32) 0 0;
    }
    &__descr {
      margin: rem(12) 0 0;
    }
    &__btn {
      margin: rem(24) auto 0;
      width: 100%;
      max-width: rem(155);
    }
  }
  &Table {
    margin: rem(16) 0 0;

    .head__row {
      display: flex;
      align-items: center;
      gap: rem(2);

      svg {
        flex: 0 0 auto;
        width: rem(12);
        height: rem(12);
      }
    }

    td {
      white-space: nowrap;
      padding: rem(16) rem(12);
      &:nth-child(1),
      &:nth-child(2) {
        width: 0;
        padding: rem(16) rem(16) rem(16) rem(24);
      }
      &:nth-child(2) {
        padding: rem(16) rem(0);

        @media (max-width: $tablet) {
          width: auto;
        }
      }
      &:last-child {
        width: 0;
        padding: rem(16) rem(24) rem(16) rem(12);
      }
    }
  }
}

.radioBtn {
  position: relative;

  input {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
}

.chains {
  display: flex;
  gap: rem(2);
  flex-direction: column;
  align-items: flex-start;
}

.diff {
  &.plus {
    color: cl(other-green);
  }
  &.minus {
    color: cl(other-red);
  }
}
