@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  cursor: pointer;
  display: flex;
  gap: rem(4);
  align-items: center;
  border: rem(1) dashed cl(opacity-light-10);
  border-radius: rem(16);
  padding: rem(16);

  transition: all $time $tr;

  &:hover {
    border: rem(1) dashed cl(opacity-light-50);

    .card__icon,
    .card__plus {
      path {
        stroke-opacity: 1;
      }
    }
  }

  &:not(:first-child) {
    margin: rem(16) 0 0;
  }

  &__icon {
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }
  &__title {
    display: flex;
    gap: rem(4);
  }
  &__plus {
    margin: 0 0 0 auto;
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }
}
