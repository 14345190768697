@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.promoted {
  position: relative;

  &__slide {
    width: rem(60) !important;
  }
}
