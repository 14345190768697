@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.airdrop {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);
  }
}

.row {
  display: flex;
  gap: rem(8);

  &__select {
    flex: 1 1 auto;
  }
}
