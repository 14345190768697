@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.rate {
  padding: rem(24);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);

  @media (max-width: $mobile) {
    padding: rem(24) rem(16);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: rem(6);

    &Coin {
      display: flex;
      align-items: center;
      gap: rem(6);

      color: cl(opacity-light-50);
    }
  }
  &__text {
    margin: rem(24) 0 0;
    max-width: rem(140);

    @media (max-width: $mobile) {
      margin: rem(24) 0 0;
    }
  }
  &Body {
    margin: rem(28) 0 0;
    max-width: rem(345);
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);

    @media (max-width: $mobile) {
      max-width: rem(280);
    }

    &__item {
      padding: rem(8) rem(12) !important;

      span {
        color: cl(main-light);
      }
    }
  }
}
