@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content {
    margin: rem(24) 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);
  
    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }
  
    &__card {
      flex: 0 0 calc(25% - rem(12));
  
      @media (max-width: $tablet) {
        flex: 0 0 calc(33.333% - rem(10.66));
      }
      @media (max-width: $mobile) {
        flex: 0 0 calc(100%);
      }
    }
  
    &__pagination {
      margin: rem(32) auto 0;
    }
  }
}


