@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.promoted-slider {
  .swiper-button-prev {
    left: 0;
    transform: scale(-1, 1);
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: rem(100);
    background: linear-gradient(
      90deg,
      rgba(1, 1, 6, 0) 0%,
      rgba(1, 1, 6, 1) 100%
    );
    transition: all $time $tr;

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}
