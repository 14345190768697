@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.airdrop {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);
  }
}

.row {
  display: flex;
  gap: rem(8);

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    gap: rem(12) rem(8);
  }

  &__select {
    flex: 1 1 auto;

    @media (max-width: $mobile) {
      flex: 1 1 calc(50% - rem(4));
    }

    &.middle {
      flex: 2 1 auto;

      @media (max-width: $mobile) {
        flex: 1 1 calc(50% - rem(4));
      }
    }
  }
}

.tokenomics {
  display: flex;
  flex-direction: column;
  gap: rem(12);

  &Row {
    display: flex;
    gap: rem(8);

    &__title {
      flex: 1 1 100%;
      height: rem(50);
      padding: 0 rem(16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: rem(1) solid rgba(255, 255, 255, 0.1);
      border-radius: rem(16);

      &Item {
      }
      &Color {
        width: rem(16);
        height: rem(16);
        border-radius: rem(4);

        &.orange {
          background: linear-gradient(180deg, #ffa200 0%, #ffd07e 100%);
        }
        &.blue {
          background: linear-gradient(180deg, #006bb9 0%, #6ac0ff 100%);
        }
        &.greenLight {
          background: linear-gradient(140deg, #5fe7c7 0%, #fff 100%);
        }
        &.pink {
          background: linear-gradient(180deg, #ff007a 0%, #fff 100%);
        }
        &.green {
          background: linear-gradient(180deg, #00ff94 0%, #fff 100%);
        }
        &.purple {
          background: linear-gradient(145deg, #ad00ff 0%, #fff 100%);
        }
      }
    }
    &__input {
      flex: 0 0 rem(98);
    }
  }
}
