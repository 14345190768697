@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.select {
  position: relative;
  backdrop-filter: none;

  &Search {
    position: relative;

    &__dropdown {
      position: absolute;
      z-index: 1;
      top: calc(100% + rem(12));
      left: 0;
      right: 0;

      opacity: 0;
      pointer-events: none;
      user-select: none;

      transition: all $time $tr;

      &.open {
        opacity: 1;
        pointer-events: unset;
        user-select: unset;
      }
    }
  }
  &List {
    margin: rem(12) 0 0;
    display: flex;
    flex-direction: column;
    gap: rem(12);
  }
}

.results {
  display: flex;
  flex-direction: column;
  gap: rem(2);
  padding: rem(0) rem(10) rem(0) rem(12);
  border-radius: rem(24);
  backdrop-filter: blur(rem(128));
  background: cl(opacity-light-5);
  max-height: rem(484);

  @media (max-width: $mobile) {
    margin: rem(16) 0 0;
    padding: rem(0);
  }

  &Content {
    overflow: auto;
    padding: rem(12) rem(8) rem(12) 0;

    @media (max-width: $mobile) {
      padding: rem(8);
    }

    &::-webkit-scrollbar {
      width: rem(4);
      // border-right: rem(10) solid red;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rem(21);
      background: cl(main-light);
    }

    &::-webkit-scrollbar-button:vertical:start:decrement {
      display: block;
      height: rem(24);
      // background-color: red;
    }

    &::-webkit-scrollbar-button:vertical:end:increment {
      display: block;
      height: rem(24);
      // background-color: red;
    }
  }
}

.item {
  width: 100%;
  display: flex;
  gap: rem(8);
  align-items: center;
  min-height: rem(50);
  padding: rem(12) rem(16);
  border: rem(1) solid cl(opacity-light-10);
  border-radius: rem(16);

  transition: all $time $tr;

  &:hover {
    border-color: cl(opacity-light-50);
  }

  &__icon {
    flex: 0 0 auto;
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    flex: 1 1 100%;
  }
  &__close {
    cursor: pointer;

    svg {
      opacity: 0.5;
      transition: all $time $tr;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}

.dropdownItem {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(12);
  border-radius: rem(20);

  transition: all $time $tr;

  &:hover {
    background-color: cl(opacity-light-5);
  }

  .rowAddress {
    margin: 0;
    display: flex;
    align-items: center;
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(12);
    padding: rem(8) rem(12);

    @media (max-width: $mobile) {
      display: none;
    }

    &__network {
      display: flex;
      gap: rem(4);
      align-items: center;

      svg {
        flex: 0 0 auto;
        width: rem(16);
        height: rem(16);
      }
    }
    &__item {
      display: flex;
      gap: rem(4);
      align-items: center;

      margin: 0 0 0 rem(10);

      &::before {
        content: '';
        width: rem(2);
        height: rem(10);
        background-color: cl(opacity-light-10);
        margin: 0 rem(10) 0 0;
      }

      button {
        width: rem(12);
        height: rem(12);

        &:hover {
          svg {
            path {
              stroke-opacity: 1;
            }
          }
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all $time $tr;
          }
        }
      }
    }
  }
}
