@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(12);
  padding: rem(52) 0 rem(16);

  &__icon {
    position: relative;
    padding: rem(12) 0 0;

    img {
      position: relative;
      z-index: 2;
      width: rem(148);
      height: auto;
      object-fit: contain;
    }

    &Stars {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(400);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__title {
    margin: rem(6) 0 0;
  }
  &__upload {
    margin: rem(12) 0 0;
  }
  &__select {
    margin: rem(12) 0 0;
    width: 100%;
  }
  &__add {
    width: 100%;
    margin: rem(12) 0 0;
  }
}
