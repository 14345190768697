@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);
  padding: rem(24);

  @media (max-width: $pc) {
    padding: rem(16);
  }

  &Bottom {
    margin: rem(4) 0 0;
    display: flex;
    justify-content: center;
    gap: rem(4);

    &__rank {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(16);
      height: rem(16);
      font-size: rem(9);
      border-radius: rem(5);

      background-color: cl(other-yellow, 0.2);
      color: cl(other-yellow);
    }
  }
  &Body {
    display: flex;
    gap: rem(12);
    margin: rem(30) 0 0;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 calc(50% - rem(6));
      padding: rem(24);
      border: rem(1) solid cl(opacity-light-10);
      border-radius: rem(20);

      @media (max-width: $pc) {
        padding: rem(14);
      }

      &:nth-child(1) {
        .cardBody__itemIcon {
          img {
            width: rem(20);
            height: rem(20);
          }
        }
      }
      &:nth-child(2) {
        .cardBody__itemIcon {
          img {
            width: rem(15);
            height: rem(15);
          }
        }
      }

      &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(12);
        width: rem(40);
        height: rem(40);
        background: cl(opacity-light-10);

        img {
          width: rem(14);
          height: rem(14);
          object-fit: contain;
        }
      }
      &Value {
        display: flex;
        align-items: center;
        gap: rem(6);
        margin: rem(16) 0 0;

        .diff {
          display: flex;
          align-items: center;
          gap: rem(3);
          font-weight: 500;
          font-size: rem(12);

          svg {
            flex: 0 0 auto;
            width: rem(6);
          }

          &.green {
            color: #78e053;

            svg {
              path {
                fill: #78e053;
              }
            }
          }
        }
      }
      &Text {
        margin: rem(4) 0 0;
      }
      &Btn {
        margin: rem(16) 0 0;

        @media (max-width: $tablet) {
          padding: rem(12);
        }

        &:hover {
          color: cl(main-light) !important;

          svg {
            path {
              stroke: cl(main-light);
            }
          }
        }

        &.yellow {
          border: rem(1) solid rgba(245, 206, 117, 0.1);
        }
        &.purple {
          border: rem(1) solid rgba(173, 117, 245, 0.1);
        }
      }
    }
  }
}
