@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.presale {
  margin: rem(32) 0 0;
  &__title {
  }
  &Body {
    display: flex;
    gap: rem(16);
    margin: rem(24) 0 0;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
      flex-direction: column;
    }

    &__col {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      flex: 1 1 auto;

      &:nth-child(2) {
        flex: 0 0 rem(565);

        @media (max-width: $pc) {
          flex: 0 0 rem(400);
        }
      }
    }
  }
}
