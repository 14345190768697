@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.about {
  position: relative;
  z-index: 1;
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__switcher {
      margin: 0 auto rem(12);
      color: #fff;
      text-align: center;
      font-weight: 700;

      @media (max-width: $tablet) {
        margin: 0 auto rem(4);
      }
    }
  }
}

.row {
  display: flex;
  gap: rem(8);

  &__select {
    flex: 1 1 auto;
  }
}
