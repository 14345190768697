@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.audit {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);
  }
}
