@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.content {
  display: flex;
  gap: rem(16);
  margin: rem(48) 0 0;

  @media (max-width: $mobile) {
    margin: rem(24) 0 0;
    flex-direction: column;
  }

  &Side {
    flex: 0 0 rem(140);

    @media (max-width: $mobile) {
      flex: 1 1 auto;
    }

    &__title {
      position: sticky;
      top: rem(16);
      @media (max-width: $mobile) {
        position: relative;
        top: auto;
      }
    }
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);
    flex: 1 1 auto;
    padding: rem(32);
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(12);

    @media (max-width: $mobile) {
      padding: 0;
      border: none;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: rem(12);
  padding: rem(24);
  border-radius: rem(12);
  background-color: cl(opacity-light-5);

  @media (max-width: $mobile) {
    padding: rem(16);
    gap: rem(8);
  }

  &__title {
    @media (max-width: $mobile) {
      font-size: rem(18);
    }
  }
  &__text {
  }
}
