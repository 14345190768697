@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.general {
  z-index: 1;

  &Body {
    display: flex;
    flex-direction: column;

    &__img {
      align-self: center;
    }

    &__inputs {
      margin: rem(24) 0 0;
      display: flex;
      flex-direction: column;
      gap: rem(12);
    }
  }
}
