@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.transactions {
  margin: rem(32) 0 0;

  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
    }
    &__button {
    }
  }
  &__table {
    margin: rem(24) 0 0;
    white-space: nowrap;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }

    table {
      td,
      th {
        &:last-child {
          width: 0;
          & > div {
            transform: translate(0);
          }
        }
      }
    }
  }
}

.transactionType {
  padding: rem(2) rem(8);
  font-weight: 500;
  font-size: rem(9);
  line-height: 130%;
  text-align: center;
  backdrop-filter: blur(rem(12));
  border-radius: rem(3);
  background: rgba(120, 221, 82, 0.1);
  color: #78dd52;

  &.sell {
    color: #dd5252;
    background: rgba(221, 82, 82, 0.1);
  }
}
