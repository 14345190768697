@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.list {
  position: relative;
  width: 100%;
  max-width: rem(452);
  border-radius: rem(20);
  overflow: hidden;

  @media (max-width: $mobile) {
    transform: translate(rem(-1), 0);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: rem(84);
    height: 100%;
    // background: linear-gradient(
    //   90deg,
    //   rgba(1, 1, 6, 1) 0%,
    //   rgba(1, 1, 6, 0) 100%
    // );
    background: linear-gradient(90deg, #1d1f24 0%, rgba(29, 31, 36, 0) 100%);
    // background: red;
  }
  &::before {
    left: rem(-2);
  }
  &::after {
    right: rem(-2);
    transform: scale(-1, 1);
  }

  &Row {
    display: flex;
    gap: rem(8);
    margin: 0 0 0 rem(8);
  }
}
