@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.banner {
  margin: rem(32) auto 0 !important;

  @media (max-width: $tablet) {
    margin: rem(24) auto 0 !important;
  }
}
