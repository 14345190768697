@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.con {
  @media (max-width: $tablet) {
    padding: 0;
  }
}

.banner {
  margin: rem(32) 0 0;
  position: relative;
  width: 100%;
  height: rem(275);
  border: rem(1) dashed cl(opacity-light-10);
  border-radius: rem(20);
  overflow: hidden;

  @media (max-width: $tablet) {
    height: rem(97);
    margin: rem(16) 0 0;
    border-radius: 0;
  }

  &__img {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__add {
    position: absolute;
    top: rem(24);
    right: rem(24);
    background: rgba(1, 1, 6, 0.4) !important;

    @media (max-width: $tablet) {
      top: rem(9);
      left: rem(14);
      right: auto;
    }

    &:hover {
      background: cl(main-light) !important;

      svg {
        path {
          stroke: cl(main-dark);
        }
      }
    }
  }
}
