@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.panels {
  &:not(:first-child) {
    margin: rem(32) 0 0;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }
  }

  &__item {
    flex: 1 1 calc(25% - rem(12));
    width: calc(25% - rem(12));

    @media (max-width: $pc) {
      flex: 1 1 calc(33.333% - rem(10.666));
      width: calc(33.333% - rem(10.666));
    }
    @media (max-width: $tablet) {
      flex: 1 1 calc(100%);
      width: calc(100%);
    }
  }
}
