@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.menu {
  position: fixed;
  z-index: 11;
  inset: 0;
  padding: rem(50) 0;
  height: calc(100vh);
  overflow: auto;

  // opacity: 0;
  pointer-events: none;
  user-select: none;
  visibility: hidden;

  @media (max-width: $tablet) {
    z-index: 10;
  }

  &.open {
    pointer-events: unset;
    user-select: unset;
    visibility: visible;

    .menu {
      &__bg {
        opacity: 1;
      }
      &Top {
        opacity: 1;
      }
      &Body {
        opacity: 1;
        transform: translate(0);
      }
    }
  }

  .con {
    display: flex;
    justify-content: flex-end;
    // height: calc(100vh);
    // overflow: auto;
    // padding: rem(50) rem(16);
  }

  &__bg {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(rem(20));
    background: cl(main-dark, 0.1);

    opacity: 0;

    transition: all $time $tr;
  }
  &Btns {
    display: flex;
    gap: rem(12);
    margin: 0 0 rem(8);

    &__item {
      flex: 1 1 auto;
      padding: rem(12) rem(8);
    }
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    width: rem(382);

    opacity: 0;
    transform: translate(0, rem(-24));

    transition: all $time $tr;

    @media (max-width: $tablet) {
      padding: rem(76) 0 0;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // backdrop-filter: blur(rem(200));
    // background: cl(opacity-light-10);
    background-color: #28272d;
    padding: rem(10) rem(24);
    border-radius: rem(24);

    opacity: 0;

    transition: all $time $tr;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
