@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.roadmap {
  &Body {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    &__add {
      margin: 0 !important;
    }
  }
}
