@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.title {
  font-weight: 600;
  font-size: rem(24);
  line-height: 130%;
  color: cl(main-light);

  @media (max-width: $mobile) {
    font-size: rem(20);
  }

  &._42 {
    font-size: rem(42);

    @media (max-width: $mobile) {
      font-size: rem(20);
    }
  }
  &._48 {
    font-size: rem(48);

    @media (max-width: $mobile) {
      font-size: rem(24);
    }
  }

  &.h2 {
    font-size: rem(18);

    &._20 {
      font-size: rem(20);

      @media (max-width: $mobile) {
        font-size: 500;
        font-size: rem(14);
      }
    }
  }
  &.h3 {
    font-size: rem(14);

    &._16 {
      font-size: rem(16);
    }
  }

  &.center {
    text-align: center;
  }
  &.blue {
    color: cl(other-blue);
  }
  &.grey {
    color: cl(opacity-light-50);
  }
}
