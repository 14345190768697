@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: rem(16);

  &:not(:first-child) {
    margin: rem(32) 0 0;
  }

  &Contacts {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: rem(4);
  }
}

.banner {
  flex: 1 1 100%;
  height: rem(100);
  border-radius: rem(24);
  overflow: hidden;

  &.half {
    flex: 1 1 calc(50% - rem(8));
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
