@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.tokenomics {
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);
  padding: rem(24);

  @media (max-width: $mobile) {
    padding: rem(16);
  }

  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
    }
    &__btn {
    }
  }
  &Chart {
    position: relative;
    display: flex;
    justify-content: center;
    margin: rem(30) 0 0;

    @media (max-width: $mobile) {
      margin: rem(32) auto 0;
      width: rem(248);
      height: rem(248);
    }

    &__item {
      // width: rem(400);
      // height: rem(400);

      @media (max-width: $mobile) {
        // width: rem(248);
        // height: rem(248);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // width: rem(500);
        // height: rem(500);
      }
    }
  }

  &Body {
    display: flex;
    gap: rem(12);
    margin: rem(30) 0 0;

    @media (max-width: $mobile) {
      margin: rem(32) 0 0;
      gap: rem(16);
      flex-direction: column;
    }

    &__legend {
      flex: 1 1 auto;
      border: rem(1) solid cl(opacity-light-10);
      border-radius: rem(20);

      &Item {
        display: flex;
        align-items: center;
        gap: rem(8);
        padding: rem(22) rem(24);

        &:not(:first-child) {
          border-top: rem(1) solid cl(opacity-light-10);
        }

        .value {
          margin: 0 0 0 auto;
        }

        span {
          width: rem(16);
          height: rem(16);
          border-radius: rem(4);

          &.orange {
            background: linear-gradient(180deg, #ffa200 0%, #ffd07e 100%);
          }
          &.blue {
            background: linear-gradient(180deg, #006bb9 0%, #6ac0ff 100%);
          }
          &.lightGreen {
            background: linear-gradient(140deg, #5fe7c7 0%, #fff 100%);
          }
          &.pink {
            background: linear-gradient(180deg, #ff007a 0%, #fff 100%);
          }
          &.green {
            background: linear-gradient(180deg, #00ff94 0%, #fff 100%);
          }
          &.purple {
            background: linear-gradient(145deg, #ad00ff 0%, #fff 100%);
          }
        }
      }
    }

    &__dates {
      flex: 0 0 rem(250);
      display: flex;
      flex-direction: column;
      gap: rem(12);

      @media (max-width: $mobile) {
        gap: rem(8);
        flex: 1 1 auto;
      }

      &Item {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(4);

        background: cl(opacity-light-5);
        border-radius: rem(20);

        @media (max-width: $mobile) {
          // min-height: rem(220);
          padding: rem(23) 0 rem(22);
        }
      }
    }
  }
}
