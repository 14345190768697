@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.personal {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  &__title {
  }

  &Body {
    margin: rem(16) 0 0;
    display: flex;
    gap: rem(16);
  }

  &Slider {
    margin: rem(16) 0 0;
    overflow: visible;

    &__item {
      width: rem(256);
    }
  }

  &Graphs {
    display: flex;
    gap: rem(16);

    @media (max-width: $tablet) {
      flex-wrap: wrap;
    }

    &__item {
      @media (max-width: $tablet) {
        flex: 1 1 auto;
      }
      &:nth-child(1) {
        flex: 1 1 rem(528);
      }
      &:nth-child(2) {
        flex: 1 1 rem(800);
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: rem(24);
  flex: 0 0 calc(20% - rem(12.8));
  background: cl(opacity-light-5);
  border-radius: rem(24);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(12);
    width: rem(40);
    height: rem(40);

    &._blue {
      background: rgba(33, 150, 243, 0.1);
    }
    &._purple {
      background: rgba(173, 117, 245, 0.1);
    }
    &._orange {
      background: rgba(245, 206, 117, 0.1);
    }
    &._yellow {
      background: rgba(255, 210, 52, 0.1);
    }
    &._purple2 {
      background: rgba(132, 109, 180, 0.1);
    }

    svg {
      width: rem(16);
      height: rem(16);
    }
  }
  &__value {
    margin: rem(48) 0 0;
  }
  &__label {
    margin: rem(8) 0 0;
  }
}
