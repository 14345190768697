@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.traffic {
  position: relative;
  padding: rem(24) 0 0 rem(24);
  border-radius: rem(20);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);

  @media (max-width: $pc) {
    padding: rem(16) 0 0 rem(16);
  }

  &__link {
    cursor: pointer;
    position: absolute;
    top: rem(16);
    right: rem(16);
    width: rem(16);
    height: rem(16);

    &:hover {
      svg {
        path {
          stroke: cl(main-light);
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all $time $tr;
      }
    }
  }
  &__title {
  }
  &__text {
    margin: rem(4) 0 0;
    display: flex;
    justify-content: center;
    gap: rem(4);

    svg {
      flex: 0 0 auto;
      width: rem(16);
      height: rem(16);
    }
  }
  &Info {
    margin: rem(30) 0 0;
    padding: rem(24) 0 0 rem(24);
    border-left: rem(1) solid cl(opacity-light-10);
    border-top: rem(1) solid cl(opacity-light-10);
    border-radius: rem(20) 0 0 0;

    &__title {
    }
    &__diff {
      display: flex;
      gap: rem(3);
      margin: rem(4) 0 0;

      .diff {
        display: flex;
        align-items: center;
        gap: rem(3);
        font-weight: 500;
        font-size: rem(12);

        svg {
          flex: 0 0 auto;
          width: rem(6);
        }

        &.green {
          color: #78e053;

          svg {
            path {
              fill: #78e053;
            }
          }
        }
      }
    }
    &__chart {
      margin: rem(24) 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
