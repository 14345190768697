@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.accordion {
  padding: rem(16) rem(17);
  background-color: cl(opacity-light-5);
  border-radius: rem(16);

  &.open {
    .accordionTop__cross {
      span {
        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(0deg);
          opacity: 0;
        }
      }
    }
  }

  &Top {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
    }
    &__cross {
      position: relative;
      width: rem(20);
      height: rem(20);

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(14);
        height: rem(2);
        border-radius: rem(20);
        background-color: cl(opacity-light-50);
        transition: all $time $tr;

        &:nth-child(1) {
        }
        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
  &Content {
    height: 0;
    transition: all $time $tr;
    overflow: hidden;

    &__text {
      padding: rem(16) 0 0;
    }
  }
}
