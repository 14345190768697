@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.modal {
  display: flex;
  flex-direction: column;
  gap: rem(12);

  &__title {
  }
  &__icon {
    margin: rem(14) 0;
    height: rem(144);

    @media (max-width: $mobile) {
      margin: rem(6) 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .input {
  }
  &__upload {
    margin: rem(-4) 0 0;
  }
  &__submit {
    margin: rem(-2) 0 0;
    align-self: flex-end;
  }
}
