@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  display: flex;
  gap: rem(12);
  align-items: center;
  border: rem(1) solid cl(opacity-light-10);
  border-radius: rem(20);
  padding: rem(16) rem(24);

  &Boost {
    display: flex;
    flex-direction: column;
    gap: rem(8);
  }
  &__plus {
    flex: 0 0 auto;
    width: rem(16);
    height: rem(16);

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &Bonus {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: rem(7);

    &__x {
      padding: rem(5.5) rem(6);
      border-radius: rem(8);
      background-color: cl(other-yellow, 0.05);

      font-weight: 500;
      font-size: rem(10);
      line-height: 130%;
      text-align: center;
      color: cl(other-yellow);
    }
  }
  &__price {
    margin: 0 0 0 auto;
  }
  &__remove {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(12);
    width: rem(32);
    height: rem(32);
    margin: 0 0 0 rem(12);
    background-color: cl(opacity-light-5);
    transition: all $time $tr;

    @media (max-width: $mobile) {
      margin: 0;
    }

    &:hover {
      background-color: cl(opacity-light-10);
    }
  }
}
