@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  cursor: pointer;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;

    &:checked + .cardWrapper {
      background-color: cl(opacity-light-5);
      border: rem(1) solid transparent;
    }
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: rem(24);
    // background-color: cl(opacity-light-5);
    // border: rem(1) solid transparent;
    background-color: transparent;
    border: rem(1) solid cl(opacity-light-10);
    border-radius: rem(24);
    min-height: rem(250);
    transition: all $time $tr;

    @media (max-width: $mobile) {
      min-height: rem(155);
    }
  }

  &Icons {
    position: absolute;
    top: rem(16);
    right: rem(16);
    display: flex;
    gap: rem(6);

    svg {
      width: rem(12);
      height: rem(12);
    }
  }

  &Icon {
    width: rem(40);
    height: rem(40);
    border-radius: rem(12);
    background: rgba(121, 127, 123, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: rem(16);
      height: rem(16);
      object-fit: contain;
    }
  }

  &Name {
    margin: rem(16) 0 0;
  }

  &Text {
    margin: rem(8) 0 auto;
    max-width: rem(150);
  }

  &Price {
    display: flex;
    align-items: center;
    margin: rem(24) 0 0;

    &__item {
    }
    &__label {
      margin: 0 0 0 rem(4);
    }
    &__show {
      position: relative;
      margin: 0 0 0 rem(8);
      padding: 0 0 0 rem(8);

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: rem(1);
        height: rem(16);
        top: 50%;
        transform: translate(0, -50%);
        background-color: cl(opacity-light-10);
      }
    }
  }
}
