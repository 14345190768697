@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  cursor: pointer;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;

    &:checked + .cardWrapper {
      background-color: transparent;
      border-color: cl(opacity-light-10);

      .cardBottom__check {
        background-color: cl(main-light);

        svg {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    gap: rem(24);
    padding: rem(24);
    background-color: cl(opacity-light-5);
    border-radius: rem(24);
    min-height: rem(195);
    border: rem(1) solid transparent;
    transition: all $time $tr;

    @media (max-width: $mobile) {
      min-height: rem(155);
    }
  }

  &Top {
    display: flex;
    // flex-wrap: wrap;
    gap: rem(14);
    justify-content: space-between;

    &__boost {
      display: flex;
      flex-direction: column;
      gap: rem(8);

      &Rate {
        display: flex;
        gap: rem(3);

        span {
          width: rem(4);
          height: rem(4);
          border-radius: rem(1);
          background-color: cl(opacity-light-10);

          &.active {
            &:nth-child(1) {
              background: #f12b11;
            }
            &:nth-child(2) {
              background: #f25114;
            }
            &:nth-child(3) {
              background: #f48817;
            }
            &:nth-child(4) {
              background: #f5a419;
            }
            &:nth-child(5) {
              background: #dab819;
            }
            &:nth-child(6) {
              background: #d3d323;
            }
            &:nth-child(7) {
              background: #b1c519;
            }
            &:nth-child(8) {
              background: #9acd19;
            }
          }
        }
      }
      &Text {
      }
    }
    &__bonus {
      display: flex;
      align-items: center;
      gap: rem(8);
      padding: rem(16) rem(14);
      border: rem(1) solid cl(opacity-light-10);
      border-radius: rem(16);

      &X {
        padding: rem(5.5) rem(6);
        border-radius: rem(8);
        background-color: cl(other-yellow, 0.05);

        font-weight: 500;
        font-size: rem(10);
        line-height: 130%;
        text-align: center;
        color: cl(other-yellow);
      }
      &Text {
      }
    }
  }
  &Bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__price {
    }
    &__check {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(32);
      height: rem(24);
      border-radius: rem(8);
      background: cl(opacity-light-5);
      transition: all $time $tr;

      svg {
        width: rem(16);
        height: rem(16);
        opacity: 0;
        transform: scale(0);
        transition: all $time $tr;
      }
    }
  }
}
