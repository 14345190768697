@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.checkbox {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;

    &:checked + .checkboxBody {
      .checkboxBody {
        &__item {
          background-color: cl(main-dark);
          transition: all $time $tr;
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &Body {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: rem(6);

    &__item {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(12);
      height: rem(12);
      background: rgba(1, 1, 6, 0.1);
      border-radius: rem(2);

      svg {
        opacity: 0;
        transition: all $time $tr;
      }
    }
  }
}
