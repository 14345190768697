@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.project {
  &Body {
    display: flex;
    justify-content: flex-end;
    gap: rem(8);

    .favorite {
      svg {
        path {
          fill: cl(opacity-light-50);
        }
      }

      &.enable {
        svg {
          path {
            fill: cl(other-yellow);
          }
        }
        &:hover {
          svg {
            path {
              fill: cl(opacity-light-80);
            }
          }
        }
      }
    }
  }
}
