@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(16);
  padding: rem(24);
  border-radius: rem(24);
  background: cl(opacity-light-5);

  @media (max-width: $tablet) {
    gap: 0;
  }

  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    width: 100%;
    height: 100%;

    @media (max-width: $tablet) {
      height: rem(200);
      width: calc(100% + rem(24));
      transform: translate(rem(-12), rem(4));
    }
  }
}

.chatTooltip {
  display: flex;
  flex-direction: column;
  gap: rem(2);
  padding: rem(12) rem(16) rem(12) rem(16);
  backdrop-filter: blur(rem(33));
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem(9);

  &__title {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: rem(14);
    line-height: 130%;
    color: cl(main-light, 0.5);
  }

  &__value {
    display: flex;
    gap: rem(4);
    align-items: center;

    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: rem(14);
    line-height: 130%;
    color: cl(main-light);

    img {
      flex: 0 0 auto;
      width: rem(16);
      height: rem(16);
      object-fit: contain;
    }
  }
}
