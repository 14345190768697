@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.notification {
  position: fixed;
  display: flex;
  align-items: center;
  gap: rem(12);
  z-index: 11;
  padding: rem(5);
  left: 50%;
  bottom: rem(38);
  transform: translate(-50%, 0);
  border-radius: rem(12);
  background: cl(opacity-light-1);
  backdrop-filter: blur(rem(200));
  border-radius: rem(17);

  transition: all $time $tr;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40);
    height: rem(40);
    background: cl(opacity-light-5);
    border-radius: rem(12);

    svg {
      width: rem(16);
      height: rem(16);
      flex: 0 0 auto;
    }
  }
}
