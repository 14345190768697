@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';

.top {
  &Body {
    &__title {
    }
    &__text {
      margin: rem(12) 0 0;
    }
  }
}
