@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: rem(4);

  &.bigSpace {
    gap: rem(16);
  }

  li {
    position: relative;
    padding: 0 0 0 rem(20);

    &::before {
      content: '';
      position: absolute;
      top: rem(7);
      left: rem(7);
      width: rem(4.5);
      height: rem(4.5);
      border-radius: 50%;
      background-color: cl(main-light);
    }
  }
}
