@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  padding: rem(5.5) rem(3.5);
  border-radius: rem(8);
  display: inline-flex;

  &.big {
    padding: rem(4) rem(8);
  }
  &.yellow {
    background: cl(other-yellow, 0.05);
    color: cl(other-yellow);
  }
  &.red {
    background: cl(other-red, 0.05);
    color: cl(other-red);
  }
  &.green {
    background: cl(other-green, 0.05);
    color: cl(other-green);
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(32) rem(24) rem(48);
  background-color: cl(opacity-light-5);
  border-radius: rem(24);
}
