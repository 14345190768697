@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(84);
  height: rem(84);
  border-radius: rem(20);
  background-color: cl(opacity-light-5);

  &__img {
    padding: rem(4);
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    background-color: cl(opacity-light-5);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
