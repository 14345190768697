@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__icon {
    width: rem(132);
    height: rem(132);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    margin: rem(24) 0 0;
    text-align: center;
  }
  &__text {
    margin: rem(12) 0 0;
    text-align: center;
  }

  .button {
    margin: rem(24) 0 0;
    min-width: rem(155);
    button {
      width: 100%;
    }
  }
}
