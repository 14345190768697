@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.results {
  margin: rem(8) 0 0;
  display: flex;
  flex-direction: column;
  gap: rem(2);
  padding: rem(0) rem(10) rem(0) rem(12);
  border-radius: rem(24);
  backdrop-filter: blur(rem(128));
  background: cl(opacity-light-5);
  max-height: rem(484);

  @media (max-width: $mobile) {
    margin: rem(16) 0 0;
    padding: rem(0);
  }

  &Content {
    overflow: auto;
    padding: rem(12) rem(8) rem(12) 0;

    @media (max-width: $mobile) {
      padding: rem(8);
    }

    &::-webkit-scrollbar {
      width: rem(4);
      // border-right: rem(10) solid red;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: rem(21);
      background: cl(main-light);
    }

    &::-webkit-scrollbar-button:vertical:start:decrement {
      display: block;
      height: rem(24);
      // background-color: red;
    }

    &::-webkit-scrollbar-button:vertical:end:increment {
      display: block;
      height: rem(24);
      // background-color: red;
    }
  }
}
