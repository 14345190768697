@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.contact {
  &Body {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(24);

    @media (max-width: $mobile) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
    }

    &__bg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      user-select: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &Img {
        position: absolute;
        // left: calc(50% - rem(100));
        // transform: translate(-50%, 0);
        top: rem(100);
        left: rem(340);
        width: rem(520);

        @media (max-width: $mobile) {
          top: rem(140);
          width: rem(300);
          left: calc(50%);
          transform: translate(-50%, 0);
        }
      }
      &Stars {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: rem(24);
      max-width: rem(340);

      @media (max-width: $mobile) {
        max-width: 100%;
      }

      &Title {
        @media (max-width: $mobile) {
          max-width: rem(150);
        }
      }

      &Text {
        max-width: rem(158);
      }

      &Social {
        display: flex;
        gap: rem(8);

        @media (max-width: $mobile) {
          margin: rem(176) 0 0;
          justify-content: center;
        }
      }
    }
    &__faq {
      width: 100%;
      max-width: rem(565);
      backdrop-filter: blur(rem(100));
      background: rgba(255, 255, 255, 0.05);
      border-radius: rem(20);
      padding: rem(24);

      @media (max-width: $mobile) {
        max-width: 100%;
      }

      &Top {
        display: flex;
        gap: rem(24);
        justify-content: space-between;
        align-items: center;
      }
      &Title {
      }
      &Text {
        display: flex;
        gap: rem(3);

        a {
          &:hover {
            div {
              text-decoration: underline;
            }
          }
        }
      }
      &Content {
        margin: rem(24) 0 0;
        display: flex;
        flex-direction: column;
        gap: rem(8);
      }
    }
  }
}
