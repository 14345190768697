@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.block {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  // backdrop-filter: blur(rem(200));
  // background: cl(opacity-light-10);
  background: #28272d;
  padding: rem(24) rem(24) rem(24) rem(20);
  border-radius: rem(24);
  overflow: hidden;
  gap: 0;
  transition: all $time $tr;

  &.open {
    gap: rem(10);
    .block {
      &Top {
        &__arrow {
          svg {
            transform: scale(1);
          }
        }
      }
      &Content {
        height: 100%;
      }
    }
  }

  &Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.accordion {
      cursor: pointer;
    }

    &__title {
      font-size: rem(9);
    }
    &__arrow {
      margin: 0 rem(12) 0 0;
      width: rem(16);
      height: rem(16);
      svg {
        transform: scale(1, -1);
        transition: all $time $tr;
      }
    }
  }
  &Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(10);
    height: 0;
    transition: all $time $tr;
    overflow: hidden;
  }
}
