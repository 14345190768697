@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.promoted {
  &:not(:first-child) {
    margin: rem(24) 0 0;
  }

  &__slider {
    margin: rem(12) 0 0;
  }
}
