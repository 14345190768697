@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.footer {
  margin: rem(32) 0 rem(24);

  @media (max-width: $mobile) {
    margin: rem(16) 0 rem(24);
  }

  &Body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(24);
    padding: rem(32) rem(24) rem(32) rem(42);
    border-radius: rem(24);
    background-color: cl(opacity-light-5);

    @media (max-width: $mobile) {
      padding: rem(16);
    }

    &__logo {
      flex: 0 0 auto;
      width: rem(114);

      @media (max-width: $mobile) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__links {
      display: flex;
      flex-wrap: wrap;
      gap: rem(16) rem(64);

      @media (max-width: $mobile) {
        gap: rem(16) rem(24);
      }

      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(16);

        @media (max-width: $mobile) {
          flex: 0 0 calc(50% - rem(12));
        }
      }
    }
  }
  &Bottom {
    margin: rem(16) 0 0;
    display: flex;
    justify-content: space-between;

    &__cr {
    }
    &__links {
      display: flex;
      gap: rem(12);

      @media (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-end;
      }

      a {
      }
    }
  }
}
