@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.presale {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: rem(24);
  backdrop-filter: blur(rem(100));
  background: cl(opacity-light-5);
  border-radius: rem(20);
  width: 100%;

  @media (max-width: $pc) {
    padding: rem(16);
  }

  &__text {
    margin: rem(4) 0 0;
  }

  &__timer {
    margin: rem(30) 0 0;
    width: 100%;

    @media (max-width: $tablet) {
      margin: rem(24) auto 0;
      max-width: rem(194);
    }
  }

  &__btn {
    margin: rem(30) 0 0;

    @media (max-width: $tablet) {
      margin: rem(16) 0 0;
    }
  }
}
