@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.video {
  flex: 1 1 auto;
  position: relative;
  border-radius: rem(20);
  overflow: hidden;
  // height: 0;
  // padding: 0 0 52%;

  img,
  iframe {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
