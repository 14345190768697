@use 'sass:math';
@import '/src/scss/style.scss';
@import '/src/scss/mixins';
@import '/src/scss/colors-palette.scss';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(16);
  border: rem(1) solid cl(opacity-light-10);
  border-radius: rem(24);

  &__icon {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name {
    margin: rem(8) 0 0;
  }
  &__label {
    margin: 0;
  }
  &__score {
    margin: rem(4) 0 0;
    display: flex;
    align-items: center;
    gap: rem(2);

    font-weight: 500;
    font-size: rem(9);
    line-height: 130%;
    text-align: center;
    background: linear-gradient(227deg, #f6ff8e 0%, #f26b41 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
